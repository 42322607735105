import React from 'react';
import './hero-skills.css';
import { useNavigate } from 'react-router-dom';
import { hideNavbar } from '../header/header';
import { addStrongers } from '../../../utils/formatText';
import { OldIntegration } from '../../icons/old-integration';
import { InterogatingSmiley } from '../../icons/interogating-smiley';
import { PuzzleIntegration } from '../../icons/puzzle-integration';
import { Rose } from '../../icons/rose';


export function HeroSkills (props) {
  
  
  const nav = useNavigate();


  return (
    <section className="hero-skills">
      <div className="container">
        <div className="content">
          <div className="heading">
            <h1>{props.texts.heading.title}</h1>

            <p>
              {addStrongers(props.texts.heading.zzdescription)}
            </p>

            <button onClick={() => {
                nav("/contact");hideNavbar();
              }}
            >
              {props.texts.heading.button}
            </button>
          </div>

          <div className="feature feature1">
            <InterogatingSmiley />
            <h2>{props.texts.features.feature1.title}</h2>
            <p>{addStrongers(props.texts.features.feature1.zzdescription)}</p>
          </div>

          <div className="feature feature2">
            <OldIntegration />
            <h2>{props.texts.features.feature2.title}</h2>
            <p>{addStrongers(props.texts.features.feature2.zzdescription)}</p>
          </div>

          <div className="feature feature3">
            <PuzzleIntegration />
            <h2>{props.texts.features.feature3.title}</h2>
            <p>{addStrongers(props.texts.features.feature3.zzdescription)}</p>
          </div>

          <div className="feature feature4">
            <Rose />
            <h2>{props.texts.features.feature4.title}</h2>
            <p>{addStrongers(props.texts.features.feature4.zzdescription)}</p>
          </div>
        </div>
      </div>
    </section>
  );
}


export default HeroSkills;