import React from 'react';
import { Logo } from '../../icons/logo';
import './header.css';
import { Burger } from '../../icons/burger';
import { Link } from 'react-router-dom';




export const hideNavbar = (event) => {
  document.getElementById('navcheck').checked = false;
};


export function Header (props) {

  const handleLanguageChange = (event) => {
    const lang = props.language === 'en' ? 'fr' : 'en';
    props.setLanguage(lang);
  };

  return (
    <header>
      <input type="checkbox" id="navcheck" className="non-visible-check"/>
      
      <div className="visible-check">
        <Burger />
      </div>

      <Link className="link" to={"/"} onClick={hideNavbar}>
        <Logo />
      </Link>

      <nav>
        <ul>
          <li>
            <Link to={"/"} onClick={hideNavbar}>
              {props.texts.home}
            </Link>
          </li>

          <li>
            <Link to={"/skills"} onClick={hideNavbar}>
              {props.texts.skills}
            </Link>
          </li>
          
          <li>
            <Link to={"/about"} onClick={hideNavbar}>
              {props.texts.about}
            </Link>
          </li>

          <li>
            <Link to={"/contact"} onClick={hideNavbar}>
              {props.texts.contact}
            </Link>
          </li>
        </ul>
      </nav>
      <button className="language-selector"
              onClick={handleLanguageChange}
      >
        {props.language === 'en' ? 'FR' : 'EN'}
      </button>
    </header>
  );
}

export default Header;