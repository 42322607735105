import React from 'react';
import './wave.css';


function Wave(props) {
  return (
    <div className="wave"
      style={{ '--backgroundColor': props.backgroundColor }}
    >
      <svg
          viewBox="0 24 150 28" 
          preserveAspectRatio="none" 
          shapeRendering="auto"
      >
        <defs>
          <path id="gentle-wave" 
                d={ `M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18
                      88-18 58 18 88 18 v44h-352z` }
          />
        </defs>
        
        <g className="wave-parallax">
          <use xlinkHref="#gentle-wave" x="48" y="7" 
                fill={ props.color } opacity="1" 
          />
        </g>
        <div className="tape" style={{'backgroundColor': + props.color | ''}}></div>
      </svg>
    </div>
  );
}


export default Wave;