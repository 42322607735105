import React from 'react';
import './who.css';
import { useNavigate } from 'react-router-dom';
import { hideNavbar } from '../header/header';
import { addStrongers } from '../../../utils/formatText';


export function Who  (props) {
  
  const nav = useNavigate();

  return (
    <section className="who">
      <div className="container">
        <img
          src="/images/robot.webp"
          alt={props.texts.image.alt}
          width="256"
          height="288"
        />

        <h1>{props.texts.title}</h1>

        <div className="description">
          <p>{addStrongers(props.texts.description.zzpart1)}</p>
          <p>{addStrongers(props.texts.description.zzpart2)}</p>
        </div>

        <div className="buttons">
          <button onClick={() => {
              nav("/about");hideNavbar();
            }}
          >
            {props.texts.button1}
          </button>
          <button onClick={() => {
              nav("/contact");hideNavbar();
            }}
          >
            {props.texts.button2 }
          </button>
        </div>
      </div>
    </section>
  );
}


export default Who;