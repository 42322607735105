import React from 'react';
import Wave from '../components/transitions/wave/wave';
import Hero from '../components/sections/hero/hero';
import Who from '../components/sections/who/who';
import What from '../components/sections/what/what';
import BarSM from '../components/sections/bar-sm/bar-sm';
import BarProjects from '../components/sections/bar-projects/bar-projects';
import BarPartners from '../components/sections/bar-partners/bar-partners';
import Services from '../components/sections/services/services';
import CTARich from '../components/sections/cta-rich/cta-rich';
import FAQ from '../components/sections/faq/faq';
import { Helmet } from 'react-helmet-async';



function HomePage({ texts }) {
  return (
    <>
      <Helmet>
        <title>{texts.titles.home}</title>
        <meta name="description" content={texts.metaDescriptions.home}></meta>
      </Helmet>
      <Hero texts={texts.hero} />
      <Wave color="var(--light)" backgroundColor="var(--main-lighter)" />
      <Who texts={texts.who} />
      <BarSM texts={texts.barSm} />
      <BarProjects texts={texts.barprojects} />
      <BarPartners texts={texts.barPartners} />
      <Wave color="var(--light-darker)" backgroundColor="var(--light)" />
      <Services texts={texts.services} />
      <Wave color="var(--light)" backgroundColor="var(--light-darker)" />
      <What texts={texts.what} />
      <Wave color="var(--light-darker)" backgroundColor="var(--light)" />
      <CTARich texts={texts.ctaRich} />
      <FAQ texts={texts.faq.home} />
      <Wave color="var(--main)" backgroundColor="var(--light-darker)"/>
    </>
  );
}



export default HomePage;