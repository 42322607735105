import React from 'react';
import './faq.css';
import { useNavigate } from 'react-router-dom';
import { hideNavbar } from '../header/header';


export function FAQ (props) {
  const nav = useNavigate();

  return (
    <section className="faq">
      <div className="container">
        <h1 className="fullh1">{props.texts.title}</h1>

        <div className="couples">
          {props.texts.couples.map((couple, index) => (
            <div className="couple" 
                 key={index}
                 itemProp="mainEntity" 
                 itemScope itemType="https://schema.org/Question">
                <label>
                    <p className="question" itemProp="name">{ couple.question }</p>
                    <input type="checkbox" 
                        className="non-visible-check">
                    </input>
                    <hr />
                    <div className="answer"
                         itemProp="acceptedAnswer"
                         itemScope itemType="https://schema.org/Answer"
                    >
                      <p itemProp="text">{ couple.answer }</p>
                    </div>
                </label>
            </div>
          ))}
      </div>
      <button onClick={() => {
          nav("/contact");hideNavbar();
        }}
      >
        {props.texts.button}
      </button>
      </div>
    </section>
  );
}

export default FAQ;