import React from 'react';
import Wave from '../components/transitions/wave/wave';
import BarSM from '../components/sections/bar-sm/bar-sm';
import BarProjects from '../components/sections/bar-projects/bar-projects';
import HeroAbout from '../components/sections/hero-about/hero-about';
import Projects from '../components/sections/projects/projects';
import About from '../components/sections/about/about';
import Team from '../components/sections/team/team';
import CTA from '../components/sections/cta/cta';
import CrealliaAd from '../components/sections/creallia-ad/creallia-ad';
import MiaAd from '../components/sections/mia-ad/mia-ad';
import PepiteAd from '../components/sections/pepite-ad/pepite-ad';
import FAQ from '../components/sections/faq/faq';
import { Helmet } from 'react-helmet-async';



function AboutPage({ texts }) {
  return (
    <>
      <Helmet>
        <title>{texts.titles.about}</title>
        <meta name="description" content={texts.metaDescriptions.about}></meta>
      </Helmet>
      <HeroAbout texts={texts.heroAbout} />
      <Wave color="var(--light-darker)" backgroundColor="var(--light)"/>
      <Projects texts={texts.projects} />
      <Wave color="var(--light)" backgroundColor="var(--light-darker)"/>
      <About texts={texts.about} textsBarSM={texts.barSm} />
      <BarSM texts={texts.barSm} />
      <Team texts={texts.team} />
      <Wave color="var(--light-darker)" backgroundColor="var(--light)"/>
      <div style={{'backgroundColor': 'var(--light-darker)'}}>
        <BarProjects texts={texts.barprojects} />
      </div>
      <Wave color="var(--light)" backgroundColor="var(--light-darker)"/>
      <CTA texts={texts.cta} />
      <CrealliaAd texts={texts.crealliaAd} />
      <MiaAd texts={texts.miaAd} />
      <PepiteAd texts={texts.pepiteAd} />
      <Wave color="var(--light-darker)" backgroundColor="var(--light)"/>
      <FAQ texts={texts.faq.about} />
      <Wave color="var(--main)" backgroundColor="var(--light-darker)"/>
    </>
  );
}



export default AboutPage;