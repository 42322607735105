import React from 'react';
import './bar-partners.css';
// import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import { hideNavbar } from '../header/header';


export function BarPartners (props) {
  // const nav = useNavigate();
  
  return (
    <section className="bar-partners">
      <div className="container">
        <h1>{props.texts.title}</h1>
        <div className="content">
          {props.texts.partners.map((partner, index) => (
              <Link key={index} to={`/about#${partner.anchor}`}>
                <img key={index}
                     src={partner.logo.src}
                     alt={partner.logo.alt}
                     width="256"
                     height="256"
                    //  onClick={() => {nav("/about/#partners");hideNavbar();}}
                />
              </Link>
          ))}
        </div>
      </div>
    </section>
  );
}


export default BarPartners;