export const addBolders = (text) => {
  const parts = text.split("**");
  const spans = parts.map((par
    , i) => i % 2 === 0 ? par : <b key={i}>{par}</b>);
  return spans;
}

export const addStrongers = (text) => {
  const parts = text.split("**");
  const spans = parts.map((par
    , i) => i % 2 === 0 ? par : <strong key={i}>{par}</strong>);
  return spans;
}