import React from 'react';
import Wave from '../components/transitions/wave/wave';
import Contact from '../components/sections/contact/contact';
import FAQ from '../components/sections/faq/faq';
import { Helmet } from 'react-helmet-async';



function ContactPage({ texts }) {
  return (
    <>
      <Helmet>
        <title>{texts.titles.contact}</title>
        <meta name="description" content={texts.metaDescriptions.contact}></meta>
      </Helmet>
      <Contact 
        texts={texts.contact} 
        textsContactForm={texts.contactForm}
        textsBarSM={texts.barSm}
      />
      <Wave color="var(--light-darker)" backgroundColor="var(--light)"/>
      <FAQ texts={texts.faq.contact} />
      <Wave color="var(--main)" backgroundColor="var(--light-darker)"/>
    </>
  );
}



export default ContactPage;