import React, { useState } from 'react';
import './projects.css';
import { addStrongers } from '../../../utils/formatText';
import { Arrow } from '../../icons/arrow';
import { useLocation } from 'react-router-dom';


export function Projects (props) {

  let defaultSlide = 0;
  const location = useLocation();
  if (location.hash) {
    const args = location.hash.split("?=");
    if (args[0] === "#projects" && args.length > 1) {
      defaultSlide = parseInt(args[1]);
    }
  }

  const [currentSlide, setCurrentSlide] = useState(defaultSlide);

  const nextSlide = () => {
    setCurrentSlide(
      (currentSlide + 1) % props.texts.projects.length
    );
  };

  const prevSlide = () => {
    setCurrentSlide(
      currentSlide === 0 ? props.texts.projects.length - 1 : currentSlide - 1
    );
  };
  
  return (
    <section className="projects" id="projects">
      <div className="container">
        <h1>{props.texts.title}</h1>

        <div className="projects-container">
          {props.texts.projects.map((project, index) => (
              <div hidden className={`project${index} project ${index === currentSlide ? 'active' : ''}`} key={index}>
                <img className="pc-cover" src={project.image.src}
                      alt={project.image.alt}
                      width="960"
                      height="742"
                />

                <div className="project-text">
                  <h2>{project.client}</h2>

                  <img className="logo" src={project.logo.src}
                        alt={project.logo.alt}
                        width="388"
                        height="170"
                  />

                  <img className="phone-cover" src={project.image.src}
                      alt={project.image.alt}
                      width="960"
                      height="742"
                  />

                  <p>{addStrongers(project.zzdescription)}</p>
                </div>
              </div>
          ))}
          <div className="arrows">
            <div className="arrow-left" onClick={prevSlide}>
              <Arrow />
            </div>

            <div className="arrow-right" onClick={nextSlide}>
              <Arrow />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;