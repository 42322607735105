import React, { useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Header from './components/sections/header/header';
import Footer from './components/sections/footer/footer';
import texts_En from './lang/en.json';
import texts_Fr from './lang/fr.json';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import NotFoundPage from './pages/NotFoundPage';
import ContactPage from './pages/ContactPage';
import LegalNoticePage from './pages/LegalNoticePage';
import SkillsPage from './pages/SkillsPage';
import ScrollToHashOrToTopCustom from "./utils/ScrollToHashOrToTopCustom";



function App() {
  const [language, setLanguage] = useState('fr');
  const texts = language === 'fr' ? texts_Fr : texts_En;

  return (
    <BrowserRouter>
      
      <Header 
        texts={texts.header}
        language={language}
        setLanguage={setLanguage}
      />

      <Routes>
        <Route exact path='/' element={<HomePage texts={texts}/>} />
        <Route exact path='/about' element={<AboutPage texts={texts} />} />
        <Route exact path='/contact' element={<ContactPage texts={texts} />} />
        <Route exact path='/notice' element={<LegalNoticePage texts={texts} />} />
        <Route exact path='/skills' element={<SkillsPage texts={texts} />} />
    		<Route exact path='*'element={<NotFoundPage texts={texts} />} />
      </Routes>

      <Footer texts={texts.footer} />
      
      <ScrollToHashOrToTopCustom />
    </BrowserRouter>
  );
}



export default App;