import React from "react";
export const Burger = () => (
  <svg className="burger" xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" viewBox="0 0 62 52">
    <g fill="var(--main)">
      <path d="
        M 11.31 0.00
        L 33.56 0.00
        Q 42.61 0.96 50.17 5.58
        Q 52.49 7.00 49.77 7.00
        L 1.56 6.99
        Q -0.13 6.99 1.03 5.54
        Q 4.95 0.59 11.31 0.00
        Z"
      />
      <path d="
        M -0.01 21.85
        L -0.01 14.13
        L 58.32 14.10
        A 0.79 0.65 -7.5 0 1 59.04 14.55
        L 61.04 20.58
        A 1.18 1.08 -8.3 0 1 59.88 22.00
        L -0.01 21.85
        Z"
      />
      <path d="
        M 62.00 29.12
        L 62.00 30.31
        L 59.62 36.16
        A 1.35 1.19 -77.0 0 1 58.46 36.99
        L -0.02 36.71
        L -0.01 29.15
        L 62.00 29.12
        Z"
      />
      <path d="
        M 33.69 52.00
        L 9.69 52.00
        Q 2.80 50.96 0.44 44.53
        A 0.36 0.35 79.7 0 1 0.77 44.05
        L 52.08 44.09
        Q 53.96 44.09 52.43 45.18
        Q 44.05 51.16 33.69 52.00
        Z"
      />
    </g>
</svg>
);

export default Burger;