import React, { useState } from 'react';
import './contact.css';
import { ContactForm } from '../contactForm/contactForm';
import BarSM from '../bar-sm/bar-sm';



export function Contact (props) {
  const [shown, setVisibility] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendNotif = (status) => {
    setSuccess(status);
    setVisibility(true);
  };

  const handleNotifClick = () => {
    setVisibility(false);
  };

  const stopclick = (event) => {
    event.stopPropagation();
  };
  
  return (
    <section className="contact">
      <div onClick={handleNotifClick} 
           className={`contact-success contact-notification ${
                    success && shown ? 'active' : 'hidden'}`} 
      >
          <p className="contact-notification-message"
             onClick={stopclick}
          >
            { props.texts.notification.success }
          </p>
      </div>

      <div onClick={handleNotifClick} 
           className={`contact-fail contact-notification ${
                    !(success) && shown ? 'active' : 'hidden'}`} 
      >
          <p className="contact-notification-message"
             onClick={stopclick}
          >
            { props.texts.notification.fail }
          </p>
      </div>

      <ContactForm 
        texts={props.textsContactForm}
        sendNotif={sendNotif}
      />

      <BarSM texts={props.textsBarSM} />

    </section>
  );
}


export default Contact;