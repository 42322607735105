import React from "react";
export const Logo = () => (
  <svg className="logo" version="1.2" viewBox="0 0 700 176" xmlns="http://www.w3.org/2000/svg">
    <path d="m109.73 4.28q-7.89 13.72-23.6 10.82a2.15 1.62 79.6 0 1-1.35-1.24l-2.57-6.11q12.21-11.42 27.52-3.47z" fill="#c442ea"/>
    <path d="m109.73 4.28 1.02 0.68q-3.37 5.32-8.79 8.54-7.51 3.61-15.77 2.51a0.6 0.57 12 0 0-0.65 0.4q-0.95 3.47 0.71 6.8l-1.13-2.53q-0.63-1.42-1.36-0.2-0.98 1.63-3.01 2.56l-0.25-0.08q8.33-6.22 0.9-14.37 0.25-0.48 0.81-0.84l2.57 6.11a2.15 1.62 79.6 0 0 1.35 1.24q15.71 2.9 23.6-10.82z" fill="#5c0f82"/>
    <path d="m110.75 4.96q9.13 6.63 12.96 17.29-0.87-0.48-1.16-1.49a0.27 0.23-25.5 0 0-0.39-0.1c-0.02 0.01-7.16-1.31-7.94-1.38-3.88-0.36-6.97-2.23-10.47-3.58a1.65 1.3-0.5 0 1-0.75-0.58l-1.04-1.62q5.42-3.22 8.79-8.54z" fill="#c442ea"/>
    <path d="m81.4 8.59q7.43 8.15-0.9 14.37c-1.13 0.46-7.96 2.83-7.79 0.29q3.13-8.05 8.69-14.66z" fill="#b227db"/>
    <path d="m101.96 13.5 1.04 1.62a1.65 1.3-0.5 0 0 0.75 0.58c3.5 1.35 6.59 3.22 10.47 3.58 0.78 0.07 7.92 1.39 7.94 1.38a0.27 0.23-25.5 0 1 0.39 0.1q0.29 1.01 1.16 1.49l8.08 18.25q-1.34 4.9 0.2 9.75 0.4 1.27-0.74 1.29c-6.33-3.55-11.16-2.09-17.59-0.12a0.45 0.45-15.3 0 1-0.58-0.33q-1.12-5.09-3.44-9.5-4.76-12.76-10.65-25.07-0.42-0.87-0.87 0.01l-6.96 13.61-4.37-5.89-0.54-1.04q-1.66-3.33-0.71-6.8a0.6 0.57 12 0 1 0.65-0.4q8.26 1.1 15.77-2.51z" fill="#b827e1"/>
    <path d="m109.64 41.59q-2.5-1.59-4.98-2.42c-5.84-1.95-9.77-4-13.5-9.03l6.96-13.61q0.45-0.88 0.87-0.01 5.89 12.31 10.65 25.07z" fill="#9700c7"/>
    <path d="m86.25 23.21 0.54 1.04c3.35 7.38 8.65 12.47 16.37 14.91q8.06 2.55 9.12 11.58 0.31 2.73-1.59 1.86c1.61-5.41-1.99-10.89-7.1-12.36-5.06-1.46-8.11-3.5-11.93-7.18a1.19 1.18 32.3 0 0-1.93 0.43q-4.1 10.82-10.45 21.1a0.87 0.83-44.9 0 0-0.02 0.87q0.73 1.19 1.56 1.78 5.05 4.09 11.18 5.47-0.98 0.46-1.5 0.29-18.13-5.78-22.96-24.25-0.76-1.38-0.58-3l5.04-10.79q4.66 0.13 8.75-1.92 2.03-0.93 3.01-2.56 0.73-1.22 1.36 0.2l1.13 2.53z" fill="#a610d2"/>
    <path d="m80.5 22.96 0.25 0.08q-4.09 2.05-8.75 1.92l0.71-1.71c-0.17 2.54 6.66 0.17 7.79-0.29z" fill="#6f009a"/>
    <path d="m86.79 24.25 4.37 5.89c3.73 5.03 7.66 7.08 13.5 9.03q2.48 0.83 4.98 2.42 2.32 4.41 3.44 9.5a0.45 0.45-15.3 0 0 0.58 0.33c6.43-1.97 11.26-3.43 17.59 0.12l1.46 0.21q2.3 7.74 8.23 13.11a1.1 0.64 49.9 0 0 0.88 0.32l0.64-0.18 0.54 1.21-0.98-0.18q-0.57-0.11-0.88-0.36-5.22-4.3-8.27-11.72a2.62 2.6-84.4 0 0-0.95-1.17c-9.06-6.26-19.53-1.55-26.19 5.97q-2.15 2.43-2.73 4.54-4.98-3.62-10 0l-1-0.58q-6.13-1.38-11.18-5.47 12.72-15 29.87-4.64 1.9 0.87 1.59-1.86-1.06-9.03-9.12-11.58c-7.72-2.44-13.02-7.53-16.37-14.91z" fill="#82008e"/>
    <path d="m110.69 52.6q-17.15-10.36-29.87 4.64-0.83-0.59-1.56-1.78a0.87 0.83-44.9 0 1 0.02-0.87q6.35-10.28 10.45-21.1a1.19 1.18 32.3 0 1 1.93-0.43c3.82 3.68 6.87 5.72 11.93 7.18 5.11 1.47 8.71 6.95 7.1 12.36z" fill="#9600c3"/>
    <path d="m66.96 35.75q-0.18 1.62 0.58 3-0.59 2.77 0.87 5.32 0.31 0.54-0.22 0.53l-1.49-0.05q-0.68-0.02-0.9 0.08-1.52 0.71-2.84-0.38l4-8.5z" fill="#9307c1"/>
    <path d="m67.54 38.75q4.83 18.47 22.96 24.25 0.52 0.17 1.5-0.29l1 0.58q-2.89 2.96-4.31 6.15-7.37 16.5-15.15 32.81-2.8-0.46-4.83-2.5 0.35-10.19 10.08-12.71c0.49-0.69 0.07-1.06-0.64-0.93q-9.38 1.67-11.1 12.97a2.6 2.58 71.9 0 1-0.71 1.41q-2.45 2.48-5.09 2.51-1.31 0.02-2.01 1.25-13.1 0.69-16.9-11.51-0.51-1.66 0.37-0.17c3.66 6.1 6.61 11.08 14.66 11.11a0.81 0.78-76.7 0 0 0.71-0.46l13.82-29.39q2.77-7.24 6.97-13.76a1.53 0.76-8 0 1 0.76-0.52c0.96-0.36 0.14-1.18-0.33-1.54q-6.03-4.67-9.6-11.47-0.91-1.73-3.21-1.32l-4.45 0.78 0.92-1.75q1.32 1.09 2.84 0.38 0.22-0.1 0.9-0.08l1.49 0.05q0.53 0.01 0.22-0.53-1.46-2.55-0.87-5.32z" fill="#7a008c"/>
    <path d="m252.25 40.21q-0.61 0.03-0.55 0.61a0.71 0.7-89 0 0 0.61 0.64l5.37 0.71a0.36 0.32 15.2 0 1 0.3 0.45q-2.77 6.26-2.97 13.14a0.89 0.88 14.6 0 1-1.3 0.75l-19.29-9.98a0.58 0.53-67.2 0 1-0.24-0.68l1.18-3.37q8.37-2.22 16.89-2.27z" fill="#6b10a0"/>
    <path d="m260.04 40.25q-4.85 9.29-3.99 19.72 0.06 0.66 0.55 0.46a0.8 0.6 79.1 0 0 0.36-0.74c-0.21-2.89 1.34-0.6 2.1-0.26q1.85 0.8 3.72 1.61a0.72 0.63-77.2 0 1 0.37 0.69q-0.05 0.43 0.1 0.73c-19.17-3.62-37.57 4.77-47.52 21.35q-10.37 0.54-17.12-6.82a0.34 0.33 36.7 0 0-0.55 0.07q-1.17 2.35-3.26 4.09a1.32 1.17-18.3 0 0-0.47 0.81c-1.23 8.87 0.39 20.61 7.36 26.75 4.04 3.56 3.69 9.36 1.33 13.78a0.36 0.28-57.3 0 0 0.04 0.41q0.1 0.08 0.23 0.11a0.68 0.48-68.2 0 0 0.62-0.4q4.26-8.81-1.82-15.13c-6.79-7.05-8.64-16.21-6.39-25.75q0.18-0.74 2.22-2.76a0.6 0.6 40.5 0 1 0.77-0.06q7.29 5.09 16.09 6.49c-12.34 25.89-0.94 54.67 26.22 63.89q-1.92 4.69-7.3 7.4a1.74 1.73-28.9 0 1-2.27-0.65q-5.32-8.88-15.33-5.32c-1.85 0.66-3.26 2.44-5.63 2.93q-0.56 0.12-0.18 0.48c0.62 0.58 1.92 0.25 2.57-0.32q6.84-6.04 14.16-1.21 6.92 7.21 1.48 15.19c-27.78-12.94-44.68-40.14-39.77-70.87q6.41-40.06 46.63-54.44l-1.18 3.37a0.58 0.53-67.2 0 0 0.24 0.68l19.29 9.98a0.89 0.88 14.6 0 0 1.3-0.75q0.2-6.88 2.97-13.14a0.36 0.32 15.2 0 0-0.3-0.45l-5.37-0.71a0.71 0.7-89 0 1-0.61-0.64q-0.06-0.58 0.55-0.61l7.79 0.04zm-47.54 85.83q-9.24-3.88-18.56-0.11c-1.77 0.71 0.17 0.92 0.61 0.75q8.67-3.42 17.32-0.07a1.26 0.89 39.1 0 0 0.73 0.05q0.78-0.26-0.1-0.62z" fill="#8b00be"/>
    <path d="m260.04 40.25q13.79 1.45 25.15 7.07c8.11 4.02 9.81 16.57 0.06 19.92-7.36 2.52-13.91-2.91-22-4.78q-0.15-0.3-0.1-0.73a0.72 0.63-77.2 0 0-0.37-0.69q-1.87-0.81-3.72-1.61c-0.76-0.34-2.31-2.63-2.1 0.26a0.8 0.6 79.1 0 1-0.36 0.74q-0.49 0.2-0.55-0.46-0.86-10.43 3.99-19.72z" fill="#6b10a0"/>
    <path d="m131.79 40.5 0.92 2c-1.77 2.75-0.51 6.35 0 9.25l-1.46-0.21q1.14-0.02 0.74-1.29-1.54-4.85-0.2-9.75z" fill="#7a09ae"/>
    <path d="m132.71 42.5 9.75 22.5-0.64 0.18a1.1 0.64 49.9 0 1-0.88-0.32q-5.93-5.37-8.23-13.11c-0.51-2.9-1.77-6.5 0-9.25z" fill="#ab17d7"/>
    <path d="m487 42.54q5.25 4.32 5.1 11.2-0.02 0.78 0.31 0.73a0.7 0.44-88.7 0 0 0.41-0.62q0.54-6.44-3.97-11.22l20.15-0.09q-2.17 3.82-5.21 7.02-1.69 1.77 0.75 1.62 3.18-0.21 6.61 1.68c1.15 0.63 2.41 0.75 2.14-1.11q1.71 6.65 5.59 12.4-3.24-2.85-4.58-7.25-5.32-6.67-14.04-4.61-9.15 2.17-14.95 9.52-0.29 0.36-0.07 0.84a0.35 0.31-41.1 0 0 0.57 0q5.31-7.31 14.31-9.11l-7.99 11-21.38-0.33q0.24-0.75-0.3-0.94-14.26-4.99-19.21-19.06a0.74 0.62 76.6 0 0-0.67-0.5q-0.93 0.08-0.44 1.2 5.65 12.88 18.36 18.83 0.47 0.22-0.04 0.25l-0.95 0.05q-11.54 0.54-23.07-0.04c-5.99-0.3-14.51-4.05-15.02-10.48-0.36-4.55 3.88-8.45 7.65-9.96q2.61-1.04 8.54-1.05 20.69-0.05 41.4 0.03z" fill="#6b10a0"/>
    <path d="m487 42.54q1.11-0.16 1.85 0.09 4.51 4.78 3.97 11.22a0.7 0.44-88.7 0 1-0.41 0.62q-0.33 0.05-0.31-0.73 0.15-6.88-5.1-11.2z" fill="#922fbb"/>
    <path d="m509 42.54c11.64-1.66 18.75 11.12 11.81 20.62q-8.62 11.8-17.81 23.13-10.22 4.5-20.04-0.74a0.38 0.37-70 0 0-0.53 0.26q-0.45 1.89-1.61 3.45-0.36 0.48 0.17 0.5 0.36 0 0.63-0.21a1.3 0.56 88.4 0 0 0.35-0.87q0.11-1.1 0.9-1.88a0.79 0.72 34.1 0 1 0.83-0.12q8.35 3.6 17.62 1.8l-30.32 39.81q-2.8-1.09-4.29-3.79-4.87-8.23-6.17-17.75l-0.08-0.5 31.67-41.71 7.99-11q7.95-2.21 14.18 3.36 1.34 4.4 4.58 7.25 1.3-0.14 0.87-0.69-6.28-8.11-5.91-18.4a0.86 0.57-82.4 0 0-0.38-0.78c-1.19-0.43-0.48 5.18-0.17 7.47 0.27 1.86-0.99 1.74-2.14 1.11q-3.43-1.89-6.61-1.68-2.44 0.15-0.75-1.62 3.04-3.2 5.21-7.02z" fill="#8b00be"/>
    <path d="m647.25 64.79q-3.97-5.86-1.23-12.36 0.23-0.57-0.24-0.46l-0.43 0.1a1.07 0.58-79.5 0 0-0.58 0.72q-1.98 6.31 1.23 12.17l-0.17 0.09a0.41 0.26 81.5 0 0-0.15 0.33l-0.07 33.15q-0.71 10.15-0.24 20.5a0.66 0.53-89.3 0 0 0.49 0.63l1.64 0.13-1.52 0.35a0.69 0.61 84.3 0 0-0.47 0.66l-0.01 28.71a0.87 0.86 90 0 0 0.86 0.87l19.89 0.16c3.38 3.99 9.26 3 12.45 6.24q3.61 3.66 3.58 9.14a0.6 0.37-84.8 0 0 0.26 0.53q0.27 0.1 0.35-0.47c0.75-5.31-2.99-11.39-8.33-12.57-2.11-0.47-3.26-0.93-5.03-1.59a1.17 0.5 74.8 0 1-0.52-0.66l-0.26-0.62c8.68-0.25 27.17-2 30.57 8.05 1.86 5.51-3.36 10.41-8.26 12.14-3.41 1.2-7.75 0.79-11.56 0.73 0.66-0.7 0.69-1.18 1.46-1.76q6.97-5.31 15.8-4.52a1.59 0.95 19.5 0 0 0.94-0.19c0.85-0.59-0.68-0.98-1.12-1q-9.61-0.53-16.86 5.71c-0.62 0.53-1.55 0.6-0.68 1.8-16.91-0.01-34.16 0.76-51.02-0.21q-3.33-0.19-3.58-2.75-0.42-4.18-0.44-8.29-0.17-54.19-0.29-108.38c-0.02-8.29 4.93-8.31 11.46-8.34q25.01-0.09 50.02 0.09c17.48 0.12 19.63 20.4 0.33 20.86q-19.13 0.46-38.27 0.31zm-1.48-15.13c-0.29-0.53-5.84-8.34-6-5.47a1.05 0.82 24.1 0 0 0.42 0.8q3.06 2.37 4.48 4.72 1.72 2.83 4.06 0.49c0.34-0.34 7.32-7.16 4.24-6.35a1.26 0.87-77.1 0 0-0.68 0.62q-1.84 3.66-5.81 5.43a0.56 0.56 63.4 0 1-0.71-0.24zm49.31 0.51c-6.44 0.32-10.27-1.62-15.02-5.58a1.05 0.94 55.7 0 0-0.88-0.22q-1.19 0.31-0.07 1.24 6.95 5.82 16.04 5.56c1.57-0.05 2.4-1.13-0.07-1zm-12.92-1c0 5.37-2.99 10.68-8.17 11.77-0.87 0.18-8.49 3.31-6.2 3.33a1.25 0.97-61.9 0 0 0.78-0.35q1.45-1.44 2.95-1.66 11.25-1.67 11.61-12.98 0.03-0.67-0.49-0.69a0.58 0.46-89.1 0 0-0.48 0.58zm-38.85 71.77q-8.58-1.47-16.05-7.41c-1.2-0.95-1.2-0.29-0.51 0.27q7.34 5.89 16.55 7.86a0.52 0.35 6.6 0 0 0.63-0.33q-0.03-0.29-0.62-0.39zm2.28 43.76q-2.13 3.25-4.9 5.52a1.14 0.71-29.8 0 0-0.44 0.82q0.06 0.63 0.83 0.55a1.36 0.84-65.2 0 0 0.77-0.51l4.31-5.61a0.62 0.62 31.8 0 1 0.77-0.18c1.25 0.61 6.28 5.22 6.3 5.22q0.56 0.01 0.2-0.48-2.5-3.45-6.33-5.68a1.13 1.13-58 0 0-1.51 0.35z" fill="#6b10a0"/>
    <path d="m470.75 64.21-3.25-0.17 0.95-0.05q0.51-0.03 0.04-0.25-12.71-5.95-18.36-18.83-0.49-1.12 0.44-1.2a0.74 0.62 76.6 0 1 0.67 0.5q4.95 14.07 19.21 19.06 0.54 0.19 0.3 0.94z" fill="#922fbb"/>
    <path d="m639.77 44.19c0.16-2.87 5.71 4.94 6 5.47a0.56 0.56 63.4 0 0 0.71 0.24q3.97-1.77 5.81-5.43a1.26 0.87-77.1 0 1 0.68-0.62c3.08-0.81-3.9 6.01-4.24 6.35q-2.34 2.34-4.06-0.49-1.42-2.35-4.48-4.72a1.05 0.82 24.1 0 1-0.42-0.8z" fill="#922fbb"/>
    <path d="m518.88 64.15q-3.88-5.75-5.59-12.4c-0.31-2.29-1.02-7.9 0.17-7.47a0.86 0.57-82.4 0 1 0.38 0.78q-0.37 10.29 5.91 18.4 0.43 0.55-0.87 0.69z" fill="#a12ccc"/>
    <path d="m680.06 44.59c4.75 3.96 8.58 5.9 15.02 5.58 2.47-0.13 1.64 0.95 0.07 1q-9.09 0.26-16.04-5.56-1.12-0.93 0.07-1.24a1.05 0.94 55.7 0 1 0.88 0.22z" fill="#922fbb"/>
    <path d="m557.82 148.48q0.85 1.05-0.06 1.49-3.07 5.35-0.51 10.88a1.29 1.18 0.5 0 0 0.68 0.63q6.54 2.55 9.57 8.98h-2v-0.17c2.85-0.43-4.68-6.45-5.64-7.12q-1.84-1.26-2.7 0.79-0.84 1.97-3.62 3.54l3.29-4.81a0.93 0.79 51.5 0 0 0.03-0.88q-2.47-5.22-0.64-10.82 0.27-0.86-0.26-1a0.99 0.62-55.9 0 0-0.82 0.36q-9.04 10.07-19.12 19.44-0.12-52.13 0.07-103.58c0.03-8.38-0.33-14.55 5.17-19.17 7.69-6.45 16.5 2.58 16.49 10.96q-0.04 45.22 0.07 90.48zm-19.14-62.78q-1.51 2.66 0.51 0.36 3.81-4.33-0.25-10.25-0.45-0.67-0.74-0.25a0.81 0.6 46.4 0 0 0.1 0.82q3.19 4.37 0.38 9.32zm16.88 5.49q-7.54-2.53-13.1-8.92a0.88 0.57 63 0 0-0.55-0.32q-0.23-0.01-0.34 0.12a0.78 0.42 46.6 0 0 0.21 0.77q5.71 6.75 14.17 9.52 0.58 0.19 0.46-0.34-0.13-0.59-0.85-0.83zm-1.86 26.92q-8.06-1.7-14.26-7.18a0.59 0.45 52.5 0 0-0.52-0.14q-0.37 0.13 0.05 0.5 6.62 5.89 15.35 7.64a0.87 0.72 23.2 0 0 0.78-0.23c0.5-0.61-0.93-0.49-1.4-0.59z" fill="#6b10a0"/>
    <path d="m71.9 73.83q-1.39 1.05-1.84 2.62a1.26 0.99-89.8 0 1-0.57 0.72c-6.54 3.26-9.69 1.15-13.75-4.16q-1.86-2.43-5.24-2.53l11.54-24.48 4.45-0.78q2.3-0.41 3.21 1.32 3.57 6.8 9.6 11.47c0.47 0.36 1.29 1.18 0.33 1.54a1.53 0.76-8 0 0-0.76 0.52q-4.2 6.52-6.97 13.76z" fill="#9307c1"/>
    <path d="m332.71 105.5q-5.82 1.39-11.64-0.48a1.02 0.94 88.7 0 1-0.62-0.64l-7.78-24.61a0.85 0.69-2.9 0 0-0.67-0.49q-0.41-0.06-0.71-0.28c-0.06-6.53-1.56-21.91 1.41-27.3 5.84-10.56 20.23-6.13 20.2 5.3q-0.06 24.25-0.19 48.5zm-1.42-25.15q-11.24-1.95-18.78-10.31a0.95 0.63 61.3 0 0-0.63-0.33c-0.56 0.03-0.44 0.43-0.16 0.74q7.77 8.65 19.28 10.75 0.3 0.05 0.59 0.01 2.59-0.36-0.3-0.86z" fill="#6b10a0"/>
    <path d="m412.46 65.25q-0.55 0-2.05-0.96c-3.67-2.35-8.65-2.57-12.45-0.03q-2.33 1.56-5.13 1.96a0.65 0.45-9.1 0 0-0.58 0.55q0.06 0.33 0.52 0.5a3.09 1.4 25 0 0 2.73 0.03q8-7.45 16.2-0.58a0.7 0.46 44.1 0 0 0.73 0.14q0.17-0.12 0.28-0.36 0.35 3.64-0.34 6.81-2.44 5.01-4.38 10.23c-1.88 5.07 4.64-3.69 4.72-3.79l-0.11 2.72q-2.36 2.04-4.57 4.34-1.84 1.91-2.84 4.62c-3.95 10.74-7.75 21.69-12.26 32.21q-1.56 3.64-2.18 7.69a0.34 0.33-3 0 0 0.42 0.37q8.14-2.1 15.81-1.17a0.99 0.99 88.5 0 0 1.09-1.15q-1.46-8.51 1.89-15.91 0.59-1.3 0.72-0.35a1.21 0.95-40.5 0 1-0.14 0.69c-1.82 3.46-5.45 18.9 1.58 18.21q-0.69 0.9-0.83 1.73c-4.98-4.92-17.8-1.99-22.79 0.46q2.52-6.44 2.51-11.21-0.05-31.31 0.03-62.59 0.01-5.86 1.41-8.69c2.82-5.72 10.23-8.15 15.56-3.97 5.55 4.36 4.78 10.51 4.45 17.5z" fill="#6b10a0"/>
    <path d="m673.99 60.94c5.18-1.09 8.17-6.4 8.17-11.77a0.58 0.46-89.1 0 1 0.48-0.58q0.52 0.02 0.49 0.69-0.36 11.31-11.61 12.98-1.5 0.22-2.95 1.66a1.25 0.97-61.9 0 1-0.78 0.35c-2.29-0.02 5.33-3.15 6.2-3.33z" fill="#922fbb"/>
    <path d="m143 66.21 3.5 8.25-0.13 0.38a1.04 0.59-13.2 0 1-0.54 0.5q-2.09 0.92-3.33 2.87-6.51 8.74-4.17 19.48a0.38 0.37 86.4 0 1-0.39 0.46l-2.94-0.19q-6.32-15.42-13.57-30.43-3.41-7.07-8.32-13.15a0.59 0.57 54.8 0 0-0.74-0.14q-5.74 3.54-9.12 9.3l-0.25-0.25q0.58-2.11 2.73-4.54c6.66-7.52 17.13-12.23 26.19-5.97a2.62 2.6-84.4 0 1 0.95 1.17q3.05 7.42 8.27 11.72 0.31 0.25 0.88 0.36l0.98 0.18z" fill="#aa0ad5"/>
    <path d="m514.3 56.9q-6.23-5.57-14.18-3.36-9 1.8-14.31 9.11a0.35 0.31-41.1 0 1-0.57 0q-0.22-0.48 0.07-0.84 5.8-7.35 14.95-9.52 8.72-2.06 14.04 4.61z" fill="#922fbb"/>
    <path d="m647.25 64.79-1.25 0.17q-3.21-5.86-1.23-12.17a1.07 0.58-79.5 0 1 0.58-0.72l0.43-0.1q0.47-0.11 0.24 0.46-2.74 6.5 1.23 12.36z" fill="#922fbb"/>
    <path d="m135 97.96q-7.69 0.02-15.2 1.9-6.55-16.64-13.61-32.8-0.47-1.07-2.94-3.52 3.38-5.76 9.12-9.3a0.59 0.57 54.8 0 1 0.74 0.14q4.91 6.08 8.32 13.15 7.25 15.01 13.57 30.43z" fill="#8100ac"/>
    <path d="m412.46 65.25 0.25 1.25q-0.11 0.24-0.28 0.36a0.7 0.46 44.1 0 1-0.73-0.14q-8.2-6.87-16.2 0.58a3.09 1.4 25 0 1-2.73-0.03q-0.46-0.17-0.52-0.5a0.65 0.45-9.1 0 1 0.58-0.55q2.8-0.4 5.13-1.96c3.8-2.54 8.78-2.32 12.45 0.03q1.5 0.96 2.05 0.96z" fill="#922fbb"/>
    <path d="m167.46 74.5q-0.59-1.35-1.9-0.85a1.23 0.97-85 0 0-0.63 0.67q-3.8 9.66-7.47 19.47-0.74 2-1.92 0.21l-8.14-17.99a0.56 0.55-22 0 0-0.69-0.29q-2.3 0.85-3.92 2.78l-0.29-0.29q1.24-1.95 3.33-2.87a1.04 0.59-13.2 0 0 0.54-0.5l0.13-0.38 0.24 0.16a0.76 0.47-27.8 0 0 0.56 0.04l19.46-5.45a0.45 0.4-9 0 1 0.56 0.36l0.14 4.93z" fill="#8b23bb"/>
    <path d="m331.29 80.35q2.89 0.5 0.3 0.86-0.29 0.04-0.59-0.01-11.51-2.1-19.28-10.75c-0.28-0.31-0.4-0.71 0.16-0.74a0.95 0.63 61.3 0 1 0.63 0.33q7.54 8.36 18.78 10.31z" fill="#922fbb"/>
    <path d="m71.9 73.83-13.82 29.39a0.81 0.78-76.7 0 1-0.71 0.46c-8.05-0.03-11-5.01-14.66-11.11q-0.88-1.49-0.37 0.17 3.8 12.2 16.9 11.51 4.43-0.48 7.88-3.33a0.89 0.8-51.7 0 1 0.85-0.15c1.29 0.53 4.23 4.61 2.44 3.99a1.09 0.73 86.2 0 1-0.55-0.71q-0.4-1.53-1.89-1.98a1.16 0.93-48.7 0 0-0.9 0.16q-4.83 3.05-10.53 3.77c-6.55-2.31-10.5-3.51-14.06-9.78a1.03 0.9-9.1 0 0-0.75-0.47q-1.8-0.23-2.29-1.74l11.06-23.53q3.38 0.1 5.24 2.53c4.06 5.31 7.21 7.42 13.75 4.16a1.26 0.99-89.8 0 0 0.57-0.72q0.45-1.57 1.84-2.62z" fill="#8510b6"/>
    <path d="m412.37 73.31q0.69 3.45 0.34 6.44c-0.08 0.1-6.6 8.86-4.72 3.79q1.94-5.22 4.38-10.23z" fill="#8b00be"/>
    <path d="m167.46 74.5 0.04 47.46-0.55 0.05-0.54-13.81q-0.08-1.99-1.96-1.91-3.96 0.16-5.14-4.21a1.5 1.33 14.1 0 0-0.53-0.77q-1.64-1.27-1.76-3.34a1.46 1.42 23.4 0 0-0.35-0.85q-1.09-1.31-1.13-3.12 1.18 1.79 1.92-0.21 3.67-9.81 7.47-19.47a1.23 0.97-85 0 1 0.63-0.67q1.31-0.5 1.9 0.85z" fill="#9000c0"/>
    <path d="m155.54 94q0.04 1.81 1.13 3.12a1.46 1.42 23.4 0 1 0.35 0.85q0.12 2.07 1.76 3.34a1.5 1.33 14.1 0 1 0.53 0.77q1.18 4.37 5.14 4.21 1.88-0.08 1.96 1.91l0.54 13.81c-6.52 2.34-16.17 1.6-21.59-3.57a0.56 0.53-48.3 0 1-0.02-0.74q6.72-8.28-0.05-15.81c-1.27-1.41-3.63-2.04-5.4-2.94a1.23 1.16 6.9 0 1-0.59-0.71q-3.16-10.72 3.49-19.74 1.62-1.93 3.92-2.78a0.56 0.55-22 0 1 0.69 0.29l8.14 17.99z" fill="#a100cd"/>
    <path d="m538.68 85.7q2.81-4.95-0.38-9.32a0.81 0.6 46.4 0 1-0.1-0.82q0.29-0.42 0.74 0.25 4.06 5.92 0.25 10.25-2.02 2.3-0.51-0.36z" fill="#922fbb"/>
    <path d="m215.73 83.81q-0.46 1.19-0.95 1.59-8.8-1.4-16.09-6.49a0.6 0.6 40.5 0 0-0.77 0.06q-2.04 2.02-2.22 2.76c-2.25 9.54-0.4 18.7 6.39 25.75q6.08 6.32 1.82 15.13a0.68 0.48-68.2 0 1-0.62 0.4q-0.13-0.03-0.23-0.11a0.36 0.28-57.3 0 1-0.04-0.41c2.36-4.42 2.71-10.22-1.33-13.78-6.97-6.14-8.59-17.88-7.36-26.75a1.32 1.17-18.3 0 1 0.47-0.81q2.09-1.74 3.26-4.09a0.34 0.33 36.7 0 1 0.55-0.07q6.75 7.36 17.12 6.82z" fill="#922fbb"/>
    <path d="m142.5 78.21 0.29 0.29q-6.65 9.02-3.49 19.74a1.23 1.16 6.9 0 0 0.59 0.71c1.77 0.9 4.13 1.53 5.4 2.94q6.77 7.53 0.05 15.81a0.56 0.53-48.3 0 0 0.02 0.74c5.42 5.17 15.07 5.91 21.59 3.57l0.55-0.05 0.73 1.57q-5.52 0.44-10.98 0.27-6.83-0.22-12.11-4.42-1.41-1.12-2.61 0.75-11.94 2.13-22.53-4.4-7.28-4.48-11.33-7.26a0.84 0.74-39.9 0 0-0.84-0.02l-0.76 0.41a0.81 0.61 79.4 0 0-0.34 0.69l0.04 3.23q0.01 0.72 0.11 0.94 1.81 4.19 1.79 8.81a1.91 1.69 32.7 0 0 0.26 0.92q1.04 1.74 1.11 3.75a1.26 0.96-28.5 0 1-0.37 0.82q-1.22 1.23-0.46 2.73-9.47-2.58-19.17-1c-4.17-7-12.89-7.94-8.93-19.2 7.98-4.79 20.08-3.54 28.92-3.02q8.45 7.85 19.41 11.48c4.9 1.63 11.22 2.42 15.05-1.63 7.32-8.18 1.9-16.99-7.89-18.11q-8.3-1.68-16.81 2.23l0.01-1.64q7.51-1.88 15.2-1.9l2.94 0.19a0.38 0.37 86.4 0 0 0.39-0.46q-2.34-10.74 4.17-19.48z" fill="#6d0fa3"/>
    <path d="m311.29 79q0.3 0.22 0.71 0.28a0.85 0.69-2.9 0 1 0.67 0.49l7.78 24.61a1.02 0.94 88.7 0 0 0.62 0.64q5.82 1.87 11.64 0.48v1.5q-12.2 1.13-21.63-6.29l0.21-21.71z" fill="#8b00be"/>
    <path d="m555.56 91.19q0.72 0.24 0.85 0.83 0.12 0.53-0.46 0.34-8.46-2.77-14.17-9.52a0.78 0.42 46.6 0 1-0.21-0.77q0.11-0.13 0.34-0.12a0.88 0.57 63 0 1 0.55 0.32q5.56 6.39 13.1 8.92z" fill="#922fbb"/>
    <path d="m412.6 82.47q0.67 24.83-0.48 49.55c-7.03 0.69-3.4-14.75-1.58-18.21a1.21 0.95-40.5 0 0 0.14-0.69q-0.13-0.95-0.72 0.35-3.35 7.4-1.89 15.91a0.99 0.99 88.5 0 1-1.09 1.15q-7.67-0.93-15.81 1.17a0.34 0.33-3 0 1-0.42-0.37q0.62-4.05 2.18-7.69c4.51-10.52 8.31-21.47 12.26-32.21q1-2.71 2.84-4.62 2.21-2.3 4.57-4.34z" fill="#8b00be"/>
    <path d="m503 86.29q-0.64 1.4-1.68 2.19-9.27 1.8-17.62-1.8a0.79 0.72 34.1 0 0-0.83 0.12q-0.79 0.78-0.9 1.88a1.3 0.56 88.4 0 1-0.35 0.87q-0.27 0.21-0.63 0.21-0.53-0.02-0.17-0.5 1.16-1.56 1.61-3.45a0.38 0.37-70 0 1 0.53-0.26q9.82 5.24 20.04 0.74z" fill="#5c0f82"/>
    <path d="m78.79 87.04q-10.82 1.17-10.83 12.04a0.79 0.46-82.6 0 0 0.28 0.67q0.2 0.1 0.47 0 2.03 2.04 4.83 2.5l-21.25 45.5-1.26-2.21q-0.14-4.06-1.79-6.32-2.49-3.42-7.24-1.76l14.54-31.46q5.7-0.72 10.53-3.77a1.16 0.93-48.7 0 1 0.9-0.16q1.49 0.45 1.89 1.98a1.09 0.73 86.2 0 0 0.55 0.71c1.79 0.62-1.15-3.46-2.44-3.99a0.89 0.8-51.7 0 0-0.85 0.15q-3.45 2.85-7.88 3.33 0.7-1.23 2.01-1.25 2.64-0.03 5.09-2.51a2.6 2.58 71.9 0 0 0.71-1.41q1.72-11.3 11.1-12.97c0.71-0.13 1.13 0.24 0.64 0.93z" fill="#670495"/>
    <path d="m78.79 87.04q-9.73 2.52-10.08 12.71-0.27 0.1-0.47 0a0.79 0.46-82.6 0 1-0.28-0.67q0.01-10.87 10.83-12.04z" fill="#8b23bb"/>
    <path d="m39.44 94.01q0.49 1.51 2.29 1.74a1.03 0.9-9.1 0 1 0.75 0.47c3.56 6.27 7.51 7.47 14.06 9.78l-14.54 31.46-25.47 6.47a0.39 0.39 67.4 0 1-0.43-0.18l-0.14-0.25 23.48-49.49z" fill="#7721ab"/>
    <path d="m645.61 98.53q15.4-0.36 30.47 0.42c20.32 1.07 17.32 20.83-0.98 20.8q-13.8-0.03-27.6 0.04l-1.64-0.13a0.66 0.53-89.3 0 1-0.49-0.63q-0.47-10.35 0.24-20.5zm14.65 1.38c-0.42-0.2-1.51-1.86-1.87-0.53a0.74 0.67 24.5 0 0 0.33 0.77q3.05 1.92 6.54 2.71c5.2 1.17 7.62 6.16 7.74 11.17a0.73 0.47 89.6 0 0 0.46 0.7q0.4 0.02 0.38-0.81-0.16-6.35-4.65-10.27c-2.38-2.08-5.88-2.23-8.93-3.74z" fill="#8b00be"/>
    <path d="m136.6 99.27q2.35 9.63 7.89 18.11c-3.83 4.05-10.15 3.26-15.05 1.63q-10.96-3.63-19.41-11.48c4.37-0.57 9.36 0.01 9.76-6.03q8.51-3.91 16.81-2.23z" fill="#8700b6"/>
    <path d="m660.26 99.91c3.05 1.51 6.55 1.66 8.93 3.74q4.49 3.92 4.65 10.27 0.02 0.83-0.38 0.81a0.73 0.47 89.6 0 1-0.46-0.7c-0.12-5.01-2.54-10-7.74-11.17q-3.49-0.79-6.54-2.71a0.74 0.67 24.5 0 1-0.33-0.77c0.36-1.33 1.45 0.33 1.87 0.53z" fill="#b227db"/>
    <path d="m136.6 99.27c9.79 1.12 15.21 9.93 7.89 18.11q-5.54-8.48-7.89-18.11z" fill="#a100cd"/>
    <path d="m311.08 100.71q9.43 7.42 21.63 6.29c-0.08 8.83-0.85 18.98 2.75 27.25l-1.71 0.71-2 0.75 1.78-2.33a0.63 0.59-46.1 0 0 0.01-0.75q-1.04-1.26-2.26-3.86-4.97-10.57-9.66-21.23a1.47 1.36 89.6 0 0-0.64-0.68q-4.64-2.41-9.69-3.86l-0.21-2.29z" fill="#6b10a0"/>
    <path d="m311.29 103q5.05 1.45 9.69 3.86a1.47 1.36 89.6 0 1 0.64 0.68q4.69 10.66 9.66 21.23 1.22 2.6 2.26 3.86a0.63 0.59-46.1 0 1-0.01 0.75l-1.78 2.33-3.98 1.21a1.25 1.17 4.1 0 0-0.72 0.58q-3.14 5.74-0.52 11.44 0.37 0.81 0.69 0.19a1.05 0.77 52.4 0 0-0.02-0.81q-2.05-4.9 0.16-9.76a1.25 1.15 1.4 0 1 0.7-0.61q3.13-1.03 5.81-2.59 0.69-0.4-0.12-0.4l1.71-0.71c8.83 17.82 31.06 22.71 46.04 9.21q8.09 5.92 17.49 9.47a1.33 0.84 33.5 0 0 0.94 0.02q0.46-0.22 0.66-0.68a0.5 0.44 18.4 0 0-0.36-0.61c-2.71-0.73-18-6.35-17.02-9.91q3.12-3.44 5.29-7.54c4.99-2.45 17.81-5.38 22.79-0.46-8.69 34.73-49.06 50.97-78.85 29.7q-15.94-11.38-20.19-31.91 4.36 4.19 10.59 6.31a2.98 2.91 46.8 0 0 1.63 0.07q0.88-0.21 1.07-0.47 0.31-0.42-0.61-0.41-2.63 0-4.93-1.33-5.05-3.73-8.46-8.96l-0.25-23.75zm42.89 54.88q-3.68-2.6-5.11-7.19-0.17-0.54-0.52-0.42a0.65 0.47 75.3 0 0-0.27 0.7c0.75 3.56 2.23 4.95 5.46 7.3q6.12 4.46 6.21 12.56c0 0.19 0.15 0.43 0.28 0.54q0.36 0.31 0.38-0.31 0.34-8.4-6.43-13.18zm-27.91-6.74c-0.33 0.54-2.13 3.03-1.53 3.14a0.73 0.53-56.4 0 0 0.53-0.2q1.36-1.27 1.61-2.71 0.3-1.73-0.61-0.23z" fill="#8b00be"/>
    <path d="m460.46 106.25 0.08 0.5-0.29 0.37a1.34 0.98 68.4 0 0-0.22 0.85q0.73 9.3 6.26 16.78l1.56 2.09a0.71 0.57 46.8 0 1 0.06 0.76q-0.64 0.83-0.7-0.31a1.6 1.31-70 0 0-0.31-0.88q-4.25-5.35-6.71-13.33-0.63-2.04-3.19-2.37l3.46-4.46z" fill="#670495"/>
    <path d="m460.54 106.75q1.3 9.52 6.17 17.75l-0.42 0.25q-5.53-7.48-6.26-16.78a1.34 0.98 68.4 0 1 0.22-0.85l0.29-0.37z" fill="#a12ccc"/>
    <path d="m142.53 120.13 2.58 10.15c-10.1 0.29-18.28 10.84-21.53 19.8a0.13 0.13 22.3 0 0 0.07 0.16l0.1 0.05 0.25 0.25-0.79 1.21q-6.83-4.35-14.46-7.04-0.32-1.68 1.2-2.28 0.6-0.24-0.02-0.49l-0.85-0.33a0.54 0.39-57.5 0 1-0.04-0.75c2.96-3.82 9.07-1.18 10.68-5.48a0.54 0.52-69.8 0 0-0.3-0.68l-10.21-3.95q-0.76-1.5 0.46-2.73a1.26 0.96-28.5 0 0 0.37-0.82q-0.07-2.01-1.11-3.75a1.91 1.69 32.7 0 1-0.26-0.92q0.02-4.62-1.79-8.81-0.1-0.22-0.11-0.94l-0.04-3.23a0.81 0.61 79.4 0 1 0.34-0.69l0.76-0.41a0.84 0.74-39.9 0 1 0.84 0.02q4.05 2.78 11.33 7.26 10.59 6.53 22.53 4.4z" fill="#8300b4"/>
    <path d="m81.11 110.55c-3.96 11.26 4.76 12.2 8.93 19.2q2.43 6.03 1.71 11.75-0.19 1.5 1 1.54c-14.64 1.47-25.54 9.49-37.34 17.38q-1.08 0.71-0.56-0.47l3.69-8.45q0.91-0.43 1.96-0.33a0.84 0.77 18.6 0 0 0.76-0.34c5.96-9.06 14.96-18.41 25.5-20.66q3.3-0.71 0.53-2.64c-2.73-1.89-5.54-3.75-6.93-6.73q-1.87-3.99-1.44-8.59 0.67-0.96 2.19-1.66z" fill="#6f009a"/>
    <path d="m457 110.71q2.56 0.33 3.19 2.37 2.46 7.98 6.71 13.33a1.6 1.31-70 0 1 0.31 0.88q0.06 1.14 0.7 0.31a0.71 0.57 46.8 0 0-0.06-0.76l-1.56-2.09 0.42-0.25q1.49 2.7 4.29 3.79l-15.29 20.27c-1.87 2.22-3.03 4.03-5.58 5.94q-3.69 2.76-5.32 7.25-0.85 2.34-3.23 3.03c-8.36 2.42-11.94-1.34-17.04-7.03q-0.1-4.27 1.65-6.56 15.39-20.25 30.81-40.48z" fill="#8b00be"/>
    <path d="m553.7 118.11c0.47 0.1 1.9-0.02 1.4 0.59a0.87 0.72 23.2 0 1-0.78 0.23q-8.73-1.75-15.35-7.64-0.42-0.37-0.05-0.5a0.59 0.45 52.5 0 1 0.52 0.14q6.2 5.48 14.26 7.18z" fill="#922fbb"/>
    <path d="m78.92 112.21q-0.43 4.6 1.44 8.59c1.39 2.98 4.2 4.84 6.93 6.73q2.77 1.93-0.53 2.64c-10.54 2.25-19.54 11.6-25.5 20.66a0.84 0.77 18.6 0 1-0.76 0.34q-1.05-0.1-1.96 0.33 5-11.79 10.26-23.46c3.49-7.72 4.83-11.48 10.12-15.83z" fill="#6e209c"/>
    <path d="m643.31 120.94q0.59 0.1 0.62 0.39a0.52 0.35 6.6 0 1-0.63 0.33q-9.21-1.97-16.55-7.86c-0.69-0.56-0.69-1.22 0.51-0.27q7.47 5.94 16.05 7.41z" fill="#922fbb"/>
    <path d="m168.23 123.53 5.31 12.22q-1.96 7.32-9.29 9.29-1.78 0.55-3.54-0.04-3.81-1.87-5.08-3.39c-3.76-4.48-2.84-10.39-10.52-11.33l-2.58-10.15q1.2-1.87 2.61-0.75 5.28 4.2 12.11 4.42 5.46 0.17 10.98-0.27z" fill="#9c00ca"/>
    <path d="m212.5 126.08q0.88 0.36 0.1 0.62a1.26 0.89 39.1 0 1-0.73-0.05q-8.65-3.35-17.32 0.07c-0.44 0.17-2.38-0.04-0.61-0.75q9.32-3.77 18.56 0.11z" fill="#922fbb"/>
    <path d="m311.54 126.75q3.41 5.23 8.46 8.96-4.4-1.82-7.05-5.74a1.56 0.84 6.2 0 0-0.74-0.51l-0.46-0.17-0.21-2.54z" fill="#5c0f82"/>
    <path d="m109.21 130.75 10.21 3.95a0.54 0.52-69.8 0 1 0.3 0.68c-1.61 4.3-7.72 1.66-10.68 5.48a0.54 0.39-57.5 0 0 0.04 0.75l0.85 0.33q0.62 0.25 0.02 0.49-1.52 0.6-1.2 2.28-7.85-2.15-16-1.67-1.19-0.04-1-1.54 0.72-5.72-1.71-11.75 9.7-1.58 19.17 1z" fill="#7b009f"/>
    <path d="m311.75 129.29 0.46 0.17a1.56 0.84 6.2 0 1 0.74 0.51q2.65 3.92 7.05 5.74 2.3 1.33 4.93 1.33 0.92-0.01 0.61 0.41-0.19 0.26-1.07 0.47a2.98 2.91 46.8 0 1-1.63-0.07q-6.23-2.12-10.59-6.31l-0.5-2.25z" fill="#a12ccc"/>
    <path d="m145.11 130.28c7.68 0.94 6.76 6.85 10.52 11.33q1.27 1.52 5.08 3.39-5.86-1.37-7.93-6.51-1.46-3.62-2.07-4.36c-9.48-11.52-23.83 8.55-26.96 16.16l-0.1-0.05a0.13 0.13 22.3 0 1-0.07-0.16c3.25-8.96 11.43-19.51 21.53-19.8z" fill="#670495"/>
    <path d="m160.71 145q1.76 0.59 3.54 0.04 6.28-0.62 8.81-6.73 0.32-0.76 1.4-0.56l16.26 38.24-1.28 0.01q-2.18-0.81-4.76-0.55a0.39 0.37-89.2 0 1-0.4-0.44q0.27-2.23-1.71-3.07-10.28-4.39-20.57-8.73c-5.72-2.68-9.9-6.55-15.12-9.96q-2.81-1.83-5.33-4.13a1.01 0.93 26.2 0 1-0.32-0.83q0.29-2.35 1.49-4.42 0.26-0.44 0.26-0.94 0.03-3.1 1.07-6.02a1.54 1.43 48.3 0 0 0.02-0.95q-0.48-1.64-0.34-2.46 0.44-2.54-1.97-1.61c-8.31 3.21-13.62 10.47-16.84 18.52a0.46 0.4-68.2 0 1-0.54 0.28l-0.38-0.15-0.25-0.25c3.13-7.61 17.48-27.68 26.96-16.16q0.61 0.74 2.07 4.36 2.07 5.14 7.93 6.51z" fill="#9700c7"/>
    <path d="m162 163.21c-8.73 3.05-1.73 5.9-1.12 9.52q0.31 1.85-1.96 2.8-8.19-1.51-14.11-6.59-12.23-10.5-21.6-17.19l0.79-1.21 0.38 0.15a0.46 0.4-68.2 0 0 0.54-0.28c3.22-8.05 8.53-15.31 16.84-18.52q2.41-0.93 1.97 1.61-0.14 0.82 0.34 2.46a1.54 1.43 48.3 0 1-0.02 0.95q-1.04 2.92-1.07 6.02 0 0.5-0.26 0.94-1.2 2.07-1.49 4.42a1.01 0.93 26.2 0 0 0.32 0.83q2.52 2.3 5.33 4.13c5.22 3.41 9.4 7.28 15.12 9.96z" fill="#8b00be"/>
    <path d="m333.75 134.96q0.81 0 0.12 0.4-2.68 1.56-5.81 2.59a1.25 1.15 1.4 0 0-0.7 0.61q-2.21 4.86-0.16 9.76a1.05 0.77 52.4 0 1 0.02 0.81q-0.32 0.62-0.69-0.19-2.62-5.7 0.52-11.44a1.25 1.17 4.1 0 1 0.72-0.58l3.98-1.21 2-0.75z" fill="#922fbb"/>
    <path d="m173.54 135.75 0.92 2q-1.08-0.2-1.4 0.56-2.53 6.11-8.81 6.73 7.33-1.97 9.29-9.29z" fill="#670495"/>
    <path d="m51.03 145.54c-2.79-3.69-3.49-10.79-10.39-6.5l-25.39 6.17 0.71-1.71 0.14 0.25a0.39 0.39 67.4 0 0 0.43 0.18l25.47-6.47q4.75-1.66 7.24 1.76 1.65 2.26 1.79 6.32z" fill="#5c0f82"/>
    <path d="m51.03 145.54 1.26 2.21c-5.7 12.66-11.92 26.65-27.85 28.25h-5.5c-0.86-1.8 0.43-5.68 0.35-8q9.89-4.5 14.88-14.05 3.75-7.17 6.47-14.91c6.9-4.29 7.6 2.81 10.39 6.5z" fill="#691898"/>
    <path d="m40.64 139.04q-2.72 7.74-6.47 14.91-4.99 9.55-14.88 14.05-1.62-6.77-6.41-11.74c-0.41-0.43-1.08-2.03-1.67-0.26l-0.96-0.29 5-10.5 25.39-6.17z" fill="#7c3aa2"/>
    <path d="m383.21 141.75c-0.98 3.56 14.31 9.18 17.02 9.91a0.5 0.44 18.4 0 1 0.36 0.61q-0.2 0.46-0.66 0.68a1.33 0.84 33.5 0 1-0.94-0.02q-9.4-3.55-17.49-9.47l1.71-1.71z" fill="#670495"/>
    <path d="m241.75 171.71q0.2-7.52 6.21-8.94 0.21-0.05 6.16-0.13 9.56-0.14 12.13-10.46 0.29-1.19-0.49-1.23a0.64 0.52-83.9 0 0-0.56 0.56c-0.99 6.87-6.09 11.43-13.13 9.98-7.49-1.54-10.05 3.82-11.36 10.01l-12.21-3.71q5.44-7.98-1.48-15.19 3.23 1.94 4.15 5.41a0.65 0.62 81.8 0 0 0.62 0.49c3.86-0.06 7.73-4.69 10.01-7.49 0.36-0.45 1.15-0.61 0.45-1.47l1.5 0.42c-3.94 3.43-5.73 7.23-11.35 9.34a0.79 0.79-8.4 0 0-0.5 0.67c-0.17 2.06-3.01 11.09 1.64 7.91q3.53-2.41 7.21-4.56c5.15-3.02 9.99-6.76 15.28-9.74 1.02-0.57 4.27-3.04-0.53-2.37q10.47-0.41 19.7-5.02c14.63-7.3 26.15 9.79 12.23 17.73q-21.14 12.06-45.68 7.79z" fill="#6b10a0"/>
    <path d="m455.71 148.56q22.72 0.33 45.22 0.45c19.94 0.1 20.83 21.23 1.47 21.25q-15.33 0.01-30.65-0.05c1.11-4.19-6.36-11.96-9.5-14.32-6.82-5.14-14.72-0.88-16.97 6.73q-0.48 1.64 0.82 1.15a0.95 0.78 87.4 0 0 0.53-0.71q1.11-6.82 8.09-8.34c7.32-1.6 14.2 8.08 16.38 14.08a1.47 0.51-66.3 0 1-0.07 0.89q-0.07 0.25-0.03 0.52l-25 0.04c1.64 0.32 1.64-6.72-3.1-5.45-6.87 1.84-12.15 1.33-17.02-4.28-0.27-0.31-0.69-0.47-0.92-0.02l-0.42-2.75c5.1 5.69 8.68 9.45 17.04 7.03q2.38-0.69 3.23-3.03 1.63-4.49 5.32-7.25c2.55-1.91 3.71-3.72 5.58-5.94zm24.23 10.67q-4.11 3.47-9.11 3.59a0.61 0.46-2.5 0 0-0.6 0.49q0.02 0.39 0.57 0.37c5.81-0.15 11.92-3.85 12.78-10.01 0.05-0.37 2.33-5.65 0.21-3.54-0.81 0.8-1.78 5.18-1.96 5.97q-0.42 1.88-1.89 3.13z" fill="#6b10a0"/>
    <path d="m557.82 148.48q17.91 0.01 35.37 0.06c5.92 0.01 15.93 1.05 18.16 8.53 2.34 7.84-7.08 13.16-13.76 13.29q-15.03 0.29-30.09 0.1-3.03-6.43-9.57-8.98a1.29 1.18 0.5 0 1-0.68-0.63q-2.56-5.53 0.51-10.88 0.91-0.44 0.06-1.49zm22.73 0.83q-0.95-1.28-1.04-0.16a0.92 0.84 20.8 0 0 0.4 0.79q2.82 1.94 6.09 2.72c5.65 1.36 8.42 5.75 8.14 11.55a0.77 0.6-81.7 0 0 0.42 0.74q0.63 0.18 0.6-0.94c-0.14-6.36-3.23-10.7-9.3-12.32-1.19-0.32-4.61-1.44-5.31-2.38z" fill="#8b00be"/>
    <path d="m580.55 149.31c0.7 0.94 4.12 2.06 5.31 2.38 6.07 1.62 9.16 5.96 9.3 12.32q0.03 1.12-0.6 0.94a0.77 0.6-81.7 0 1-0.42-0.74c0.28-5.8-2.49-10.19-8.14-11.55q-3.27-0.78-6.09-2.72a0.92 0.84 20.8 0 1-0.4-0.79q0.09-1.12 1.04 0.16z" fill="#a12ccc"/>
    <path d="m241 149.29 1.25 0.25c0.7 0.86-0.09 1.02-0.45 1.47-2.28 2.8-6.15 7.43-10.01 7.49a0.65 0.62 81.8 0 1-0.62-0.49q-0.92-3.47-4.15-5.41-7.32-4.83-14.16 1.21c-0.65 0.57-1.95 0.9-2.57 0.32q-0.38-0.36 0.18-0.48c2.37-0.49 3.78-2.27 5.63-2.93q10.01-3.56 15.33 5.32a1.74 1.73-28.9 0 0 2.27 0.65q5.38-2.71 7.3-7.4z" fill="#a12ccc"/>
    <path d="m479.94 159.23q1.47-1.25 1.89-3.13c0.18-0.79 1.15-5.17 1.96-5.97 2.12-2.11-0.16 3.17-0.21 3.54-0.86 6.16-6.97 9.86-12.78 10.01q-0.55 0.02-0.57-0.37a0.61 0.46-2.5 0 1 0.6-0.49q5-0.12 9.11-3.59z" fill="#922fbb"/>
    <path d="m243.75 149.96q5.81 1.28 11.75 1.25c4.8-0.67 1.55 1.8 0.53 2.37-5.29 2.98-10.13 6.72-15.28 9.74q-3.68 2.15-7.21 4.56c-4.65 3.18-1.81-5.85-1.64-7.91a0.79 0.79-8.4 0 1 0.5-0.67c5.62-2.11 7.41-5.91 11.35-9.34z" fill="#8b00be"/>
    <path d="m553.54 167.5c-2 3.92 3.81-2.48 3.94-2.67q1.36-2.07 3.08-0.28l4.16 4.32a0.79 0.62-59 0 1 0.1 0.79q-0.26 0.67 0.68 0.63v0.17q-14.58 0.6-29.48-0.67 10.08-9.37 19.12-19.44a0.99 0.62-55.9 0 1 0.82-0.36q0.53 0.14 0.26 1-1.83 5.6 0.64 10.82a0.93 0.79 51.5 0 1-0.03 0.88l-3.29 4.81z" fill="#8b00be"/>
    <path d="m354.18 157.88q6.77 4.78 6.43 13.18-0.02 0.62-0.38 0.31c-0.13-0.11-0.28-0.35-0.28-0.54q-0.09-8.1-6.21-12.56c-3.23-2.35-4.71-3.74-5.46-7.3a0.65 0.47 75.3 0 1 0.27-0.7q0.35-0.12 0.52 0.42 1.43 4.59 5.11 7.19z" fill="#5c0f82"/>
    <g fill="#922fbb">
      <path d="m668.75 150.54 0.26 0.62a1.17 0.5 74.8 0 0 0.52 0.66c1.77 0.66 2.92 1.12 5.03 1.59 5.34 1.18 9.08 7.26 8.33 12.57q-0.08 0.57-0.35 0.47a0.6 0.37-84.8 0 1-0.26-0.53q0.03-5.48-3.58-9.14c-3.19-3.24-9.07-2.25-12.45-6.24h2.5z"/>
      <path d="m326.27 151.14q0.91-1.5 0.61 0.23-0.25 1.44-1.61 2.71a0.73 0.53-56.4 0 1-0.53 0.2c-0.6-0.11 1.2-2.6 1.53-3.14z"/>
      <path d="m241.75 171.71-1.04-0.21c1.31-6.19 3.87-11.55 11.36-10.01 7.04 1.45 12.14-3.11 13.13-9.98a0.64 0.52-83.9 0 1 0.56-0.56q0.78 0.04 0.49 1.23-2.57 10.32-12.13 10.46-5.95 0.08-6.16 0.13-6.01 1.42-6.21 8.94z"/>
      <path d="m471.75 170.21h-0.75q-0.04-0.27 0.03-0.52a1.47 0.51-66.3 0 0 0.07-0.89c-2.18-6-9.06-15.68-16.38-14.08q-6.98 1.52-8.09 8.34a0.95 0.78 87.4 0 1-0.53 0.71q-1.3 0.49-0.82-1.15c2.25-7.61 10.15-11.87 16.97-6.73 3.14 2.36 10.61 10.13 9.5 14.32z"/>
    </g>
    <path d="m19.29 168c0.08 2.32-1.21 6.2-0.35 8h-16.75c0.64-0.7 0.93-1.12 1.88-1.43q6.9-2.28 13.12-5.55a1.7 1.7-22.5 0 0 0.84-2.02q-2.01-6.37-6.82-11c0.59-1.77 1.26-0.17 1.67 0.26q4.79 4.97 6.41 11.74z" fill="#670495"/>
    <path d="m10.25 155.71 0.96 0.29q4.81 4.63 6.82 11a1.7 1.7-22.5 0 1-0.84 2.02q-6.22 3.27-13.12 5.55c-0.95 0.31-1.24 0.73-1.88 1.43h-1.25l-0.1-0.25a0.64 0.6 49 0 1 0.02-0.48l9.39-19.56z" fill="#844a9c"/>
    <path d="m446 170.25q0.53-2.67-1.13-4.66a0.69 0.65-31 0 0-0.78-0.17q-9.21 3.87-17.02-1.94a1.11 0.75 52.2 0 0-0.7-0.23l-0.37 0.04-1.04-2.79c0.23-0.45 0.65-0.29 0.92 0.02 4.87 5.61 10.15 6.12 17.02 4.28 4.74-1.27 4.74 5.77 3.1 5.45z" fill="#922fbb"/>
    <path d="m565.5 170.29q-0.94 0.04-0.68-0.63a0.79 0.62-59 0 0-0.1-0.79l-4.16-4.32q-1.72-1.79-3.08 0.28c-0.13 0.19-5.94 6.59-3.94 2.67q2.78-1.57 3.62-3.54 0.86-2.05 2.7-0.79c0.96 0.67 8.49 6.69 5.64 7.12z" fill="#a12ccc"/>
    <path d="m162 163.21q10.29 4.34 20.57 8.73 1.98 0.84 1.71 3.07a0.39 0.37-89.2 0 0 0.4 0.44q2.58-0.26 4.76 0.55h-27.75l-2.77-0.47q2.27-0.95 1.96-2.8c-0.61-3.62-7.61-6.47 1.12-9.52z" fill="#7700bc"/>
    <path d="m426 163.29 0.37-0.04a1.11 0.75 52.2 0 1 0.7 0.23q7.81 5.81 17.02 1.94a0.69 0.65-31 0 1 0.78 0.17q1.66 1.99 1.13 4.66c-8.8 0.21-16.14 1.16-20-6.96z" fill="#7a09ae"/>
    <path d="m679.5 171.46-0.46 0.04c-0.87-1.2 0.06-1.27 0.68-1.8q7.25-6.24 16.86-5.71c0.44 0.02 1.97 0.41 1.12 1a1.59 0.95 19.5 0 1-0.94 0.19q-8.83-0.79-15.8 4.52c-0.77 0.58-0.8 1.06-1.46 1.76z" fill="#922fbb"/>
    <path d="m645.59 164.7a1.13 1.13-58 0 1 1.51-0.35q3.83 2.23 6.33 5.68 0.36 0.49-0.2 0.48c-0.02 0-5.05-4.61-6.3-5.22a0.62 0.62 31.8 0 0-0.77 0.18l-4.31 5.61a1.36 0.84-65.2 0 1-0.77 0.51q-0.77 0.08-0.83-0.55a1.14 0.71-29.8 0 1 0.44-0.82q2.77-2.27 4.9-5.52z" fill="#922fbb"/>
  </svg>
);
export default Logo;