import React from 'react';
import './team.css';
import { Linkedin } from '../../icons/linkedin';
import { Link } from 'react-router-dom';
import { addStrongers } from '../../../utils/formatText';


export function Team (props) {
  
  

  return (
    <section className="team">
      <div className="container">
        <h1>{props.texts.title}</h1>
        <img className="ceo" 
            src="/images/ceo-Acuzle.webp"
            alt={props.texts.founder.name}
            width="256"
            height="256"
          />
        <div className="member-heading">
          <h2>{props.texts.founder.name}</h2>

          <Link to={props.texts.founder.linkedin}
                  target="_blank" rel="noreferrer"
          >
            <Linkedin />
          </Link>
        </div>
        <p>
          "{addStrongers(props.texts.founder.zzdescription)}"
        </p>

      </div>
    </section>
  );
}

export default Team;