import React from "react";
export const ThreeDPuzzlePeaceTwo = () => (
  <svg className="three-d-puzzle-peace-two" version="1.2" viewBox="0 0 52 64" xmlns="http://www.w3.org/2000/svg">
    <path d="m23.06 0h1.25l9.48 6.5q-3.8 3.38-8.29 5.79-3.63-0.91-6.44-3.39a1.37 0.63 49.9 0 0-0.87-0.41l-0.69 0.05-3.89-2.47 9.45-6.07z" fill="#ff44ef"/>
    <path d="m13.61 6.07 3.89 2.47 6.96 4.46 0.5 8q-1.55-1.69-2.85 0.3a0.91 0.89-72.9 0 1-0.75 0.41c-3.25 0-3.91 3.97-7.57 5.04l-0.08-2.25q0.48-3.28-2.31-4.51a0.88 0.62 16.2 0 1-0.54-0.67l0.14-5.53q0.95 0.73 2.07 0.95a0.44 0.44 5.9 0 0 0.53-0.43l0.01-8.24z" fill="#830db1"/>
    <path d="m33.79 6.5c1.84 0.11 1.26 7.49 1.46 9.21l-8.63 5.23a1.37 0.8-19.6 0 0-0.61 0.72q-0.09 0.38-0.01 0.8l-1.04-1.46-0.5-8 1.04-0.71q4.49-2.41 8.29-5.79z" fill="#ffa2f1"/>
    <path d="m11 13.79-0.14 5.53a0.88 0.62 16.2 0 0 0.54 0.67q2.79 1.23 2.31 4.51l-5.17 2.75q-1.29-2.57-1.14-5.45a0.92 0.81 20.9 0 0-0.39-0.74l-6.55-4.56 6.84-4.28a1.19 0.95 34.4 0 1 0.93-0.07q1.54 0.52 2.77 1.64z" fill="#fa5ee2"/>
    <g fill="#ff57f1">
      <path d="m35.25 15.71 3.34 2.16-1.09 1.09q-4.18 3.06-9.04 4.79l-2.46-1.29q-0.08-0.42 0.01-0.8a1.37 0.8-19.6 0 1 0.61-0.72z"/>
      <path d="m39.46 26.08 10.83 6.92q-0.87 0.82-1.26 1.89l-6.1 3.12a1.78 1.25-47.3 0 1-0.92 0.2q-2.15-0.26-4.01-1.5c-1.57-1.23-6.64-3.39-6.91-5.11a0.82 0.65-22.7 0 1 0.41-0.74z"/>
    </g>
    <path d="m0.46 16.5 6.55 4.56a0.92 0.81 20.9 0 1 0.39 0.74q-0.15 2.88 1.14 5.45l0.21 0.46-3.12 5.37-4.78-2.98a0.96 0.89 17 0 1-0.45-0.78l0.06-12.82z" fill="#7d23b0"/>
    <g fill="#ff80ee">
      <path d="m17.5 8.54 0.69-0.05a1.37 0.63 49.9 0 1 0.87 0.41q2.81 2.48 6.44 3.39l-1.04 0.71z"/>
      <path d="m38.59 17.87q1.52 4.05 0.87 8.21l-7.96 4.78a0.82 0.65-22.7 0 0-0.41 0.74c0.27 1.72 5.34 3.88 6.91 5.11l-0.29 0.29-7.62-4.6 0.47-7.27a1.15 1.11-17.3 0 1 0.44-0.8c0.36-0.28 8.1-4.73 6.5-5.37z"/>
    </g>
    <path d="m24.96 21 1.04 1.46 2.46 1.29 1.63 8.65 7.62 4.6 3.41 2.44a1.12 0.98-76.1 0 1 0.42 0.81l0.78 12.58-0.47 3.82c-0.7-0.55-4.28-4.08-3.64-0.65q-0.78-2.82-1.07-5.72-0.25-2.5-1.37-3.66-2.33-2.43-6.1-2.14 0.2-5.71-5.14-4.38a1.39 1.22-76.5 0 0-0.77 0.54q-2.03 2.96-5.29 4.44a1.26 0.86-85.3 0 0-0.56 0.69l-0.57 1.64a0.8 0.61 37.4 0 0 0.19 0.7q0.34 0.39-0.32 1.14-1.37-0.5-2.78-0.38a0.75 0.75 5.1 0 0-0.65 0.54q-0.56 1.86 0.11 4.11l-9.09-5.38a0.95 0.79-76.7 0 1-0.39-0.79l0.05-8.6q3.9 2.25 2-2 1.5-2.16-0.83-3.67l3.12-5.37 3.81 1.8a0.7 0.56 30.8 0 0 0.78-0.11q0.87-1.07 0.45-2.65c3.66-1.07 4.32-5.04 7.57-5.04a0.91 0.89-72.9 0 0 0.75-0.41q1.3-1.99 2.85-0.3z" fill="#7806aa"/>
    <g fill="#f4b8e5">
      <path d="m37.5 18.96c1.6 0.64-6.14 5.09-6.5 5.37a1.15 1.11-17.3 0 0-0.44 0.8l-0.47 7.27-1.63-8.65q4.86-1.73 9.04-4.79z"/>
      <path d="m49.03 34.89q-2.19 2.91-5.27 4.12a1.48 1.47 75.7 0 0-0.91 1.54q0.76 6.19-0.53 12.28l-0.78-12.58a1.12 0.98-76.1 0 0-0.42-0.81l-3.41-2.44 0.29-0.29q1.86 1.24 4.01 1.5a1.78 1.25-47.3 0 0 0.92-0.2z"/>
    </g>
    <g fill="#c549d7">
      <path d="m13.71 24.5 0.08 2.25q0.42 1.58-0.45 2.65a0.7 0.56 30.8 0 1-0.78 0.11l-3.81-1.8-0.21-0.46z"/>
      <path d="m6.46 36.75q1.9 4.25-2 2 0.45-1.51 2-2z"/>
    </g>
    <path d="m29.67 44.48-0.49 19.13-7.53-4.84a0.85 0.82-72.6 0 1-0.38-0.76c0.15-2.34 0.22-6.69-2.77-7.3q-0.17-1.1-1.29-1.46 0.66-0.75 0.32-1.14a0.8 0.61 37.4 0 1-0.19-0.7l0.57-1.64a1.26 0.86-85.3 0 1 0.56-0.69q3.26-1.48 5.29-4.44a1.39 1.22-76.5 0 1 0.77-0.54q5.34-1.33 5.14 4.38z" fill="#6d00a6"/>
    <path d="m50.29 33q0.55 0.18 0.96 0.63a1.43 1.15-70.8 0 1 0.32 0.9l0.14 15.88a1.07 1.04-19.9 0 1-0.4 0.82q-4.32 3.39-9.46 5.42l0.47-3.82q1.29-6.09 0.53-12.28a1.48 1.47 75.7 0 1 0.91-1.54q3.08-1.21 5.27-4.12 0.39-1.07 1.26-1.89z" fill="#ff75f3"/>
    <path d="m17.21 49.25q1.12 0.36 1.29 1.46l-4.61 2.81q-0.67-2.25-0.11-4.11a0.75 0.75 5.1 0 1 0.65-0.54q1.41-0.12 2.78 0.38z" fill="#c918d4"/>
    <path d="m38.21 56c0.82 3.5-5.67 6.27-9.03 7.61l0.49-19.13q3.77-0.29 6.1 2.14 1.12 1.16 1.37 3.66 0.29 2.9 1.07 5.72z" fill="#ff4ef1"/>
  </svg>
);
export default ThreeDPuzzlePeaceTwo;