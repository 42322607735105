import React from 'react';
import { useNavigate } from 'react-router-dom';
import './error-section.css';

function ErrorSection(props) {
  const navigate = useNavigate();

  const redirectToSomewhere = () => {
    navigate((props.redirectTo) ? props.redirectTo : '/');
  };

  return (
    <section className="error-section">
      <h1>{ props.errorCode }</h1>

      <h2>{ props.errorMessage }</h2>
      
      <div>
        <button onClick={redirectToSomewhere}>
          { props.redirectionMessage }
        </button>
      </div>
    </section>
  );
}

export default ErrorSection;