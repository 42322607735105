import React from "react";
export const BiggerOptimization = () => (
  <svg className="bigger-optimization" version="1.2" viewBox="0 0 128 115" xmlns="http://www.w3.org/2000/svg">
    <g fill="#9b57fa">
      <path d="m88.75 28.04q5.38 4.73 5.33 11.89-0.11 14.01-0.29 28-0.06 4.62-1.34 7.41c-2.4 5.19-7.48 10.07-13.6 10.04q-11.39-0.04-22.74-0.12c-7.95-0.05-13.05-3.09-16.79-10 2.24-1.44 1.3-2.84 1.78-4.61q1.16-4.28 5.06-6.72l2.06 3.85a1.01 1-30.3 0 0 1.42 0.37l0.78-0.49a1 0.99 51.1 0 1 1.24 0.14l2.3 2.31a1 1 0 0 1 0.15 1.22l-0.54 0.9a1 1 0 0 0 0.34 1.38l2.85 1.71a1 1 0 0 0 1.39-0.38l0.48-0.88a1.01 1.01 0 0 1 1.11-0.49l3.24 0.75a1.01 1-83.5 0 1 0.77 0.98v1.06a1 1 0 0 0 1.01 1l3.25-0.04a1 1 0 0 0 0.99-1v-0.98a1 0.99 83 0 1 0.75-0.97l3.13-0.81a1 0.99 68.6 0 1 1.13 0.5l0.45 0.83a1 0.99-29.5 0 0 1.4 0.38l2.86-1.73a1.01 1.01 0 0 0 0.31-1.42l-0.46-0.68a1 1 0 0 1 0.13-1.26l2.33-2.34a1.01 1.01 0 0 1 1.3-0.1l0.61 0.45a1 1 0 0 0 1.45-0.3l1.72-2.89a1 1 0 0 0-0.35-1.37l-0.97-0.58a1 0.99-66.8 0 1-0.45-1.13l0.91-3.13a1 1 0 0 1 1.11-0.71l0.74 0.12a1 1 0 0 0 1.15-0.99v-3.46a1 1 0 0 0-1-1h-1.06a1.01 1 81.9 0 1-0.96-0.73l-0.88-3.17a1.01 1 68.8 0 1 0.5-1.15l0.86-0.46a1.01 1-29 0 0 0.4-1.39l-1.66-2.86a1.01 1.01 0 0 0-1.43-0.33l-0.73 0.5a1.01 1-39.3 0 1-1.27-0.12l-2.3-2.3a1 1 0 0 1-0.17-1.19l0.95-1.76c3.51 0.24 8.05-7.2 9.25-9.75z"/>
      <path d="m73.25 42.04q9.1 5.65 7.96 14.87c-1.9 15.32-21.43 18.69-28.42 5.09q1.33-0.08 2.25-1c5.97 10.63 21.97 8.38 23.67-3.77q1.22-8.66-6.96-13.19 1.21-0.65 1.5-2z"/>
      <path d="m69.01 51.79q-1.12-0.15-0.72-1.78a1 1 0 0 1 0.97-0.76h4.74a1 1 0 0 1 1 1v4.78a1 1 0 0 1-0.86 0.99q-1.69 0.24-1.55-1.03 0.18-1.77-1.09-0.52l-4.54 4.46a1 1 0 0 1-1.41-0.01l-1.22-1.21a1 0.99-44.8 0 0-1.41 0l-3.16 3.15a1 0.99-44 0 1-1.43-0.02l-0.36-0.38a1 1 0 0 1 0.02-1.4l4.93-4.86a1 1 0 0 1 1.41 0l1.34 1.34a1 0.99 44.8 0 0 1.41 0l2.4-2.39q1.16-1.16-0.47-1.36z" fill="#3b02a6"/>
    </g>
    <g fill="#e3cdfb">
      <path d="m92.63 27.08c7.98 5.37 18.18 13.27 20.37 23.13q-7.18 0.59-13.9 0.21a1 1 0 0 1-0.94-1.07c0.56-7.92 0.32-16.03-5.53-22.27z"/>
      <path d="m71.75 44.04q8.18 4.53 6.96 13.19c-1.7 12.15-17.7 14.4-23.67 3.77-4.24-11.49 5.27-21.76 16.71-16.96zm-2.27 9.11-2.4 2.39a1 0.99 44.8 0 1-1.41 0l-1.34-1.34a1 1 0 0 0-1.41 0l-4.93 4.86a1 1 0 0 0-0.02 1.4l0.36 0.38a1 0.99-44 0 0 1.43 0.02l3.16-3.15a1 0.99-44.8 0 1 1.41 0l1.22 1.21a1 1 0 0 0 1.41 0.01l4.54-4.46q1.27-1.25 1.09 0.52-0.14 1.27 1.55 1.03a1 1 0 0 0 0.86-0.99v-4.78a1 1 0 0 0-1-1h-4.74a1 1 0 0 0-0.97 0.76q-0.4 1.63 0.72 1.78 1.63 0.2 0.47 1.36z" fill="#fff"/>
      <path d="m113.25 53.79 0.23 5.23-14.22 0.09a1.01 1 0 0 1-1.01-1v-3.54a1 1 0 0 1 1.02-1z"/>
      <path d="m113.77 62.3c0.79 7.88 0.35 22.62-6.31 28.2l-1.71 0.71q-3.18 0.67-2.12 3.84-3.8 6.14-9.8 8.32-11.52 4.18-24.44 1.11-0.26-0.06-0.46-0.24l-15.93-15.03q19.93 0.13 24.75 0.02c12.31-0.27 19.99-9.02 20.19-21.23q0.04-2.48 0.28-4.96a1 1 0 0 1 1.01-0.9z"/>
    </g>
    <path d="m92.63 27.08c5.85 6.24 6.09 14.35 5.53 22.27a1 1 0 0 0 0.94 1.07q6.72 0.38 13.9-0.21l13.4 0.48a1 0.99-87.7 0 1 0.96 1.05l-0.05 1.05a1 1 0 0 1-1 0.95l-13.06 0.05-13.98-0.22a1 1 0 0 0-1.02 1v3.54a1.01 1 0 0 0 1.01 1l14.22-0.09q5.9-0.02 11.77 0.25 2.65 0.13 1.8 2.22a1.02 0.99 7.3 0 1-0.82 0.62q-6 0.66-12.46 0.19l-14.54-0.16a1 1 0 0 0-1.01 0.9q-0.24 2.48-0.28 4.96c-0.2 12.21-7.88 20.96-20.19 21.23q-4.82 0.11-24.75-0.02c-10.58-2.06-17.01-9.31-17.73-19.91q-0.21-3.16-0.65-6.36a1.01 1 86.3 0 0-0.98-0.87l-26.32-0.32a0.78 0.77-12.7 0 1-0.7-0.44c-0.52-1.04 0.33-1.49 1.23-1.5q12.81-0.16 25.62-0.35a1.01 1-0.6 0 0 0.99-1v-3.9a1 1 0 0 0-0.99-1.01l-25.67-0.09a1 1 0 0 1-0.93-0.63c-0.82-2.06 1.13-2.12 2.63-2.11q12.26 0.11 24.52 0.33a1.01 1-87.6 0 0 1.02-0.95q0.31-5.75 0.39-11.48c0.11-7.18 4.52-12.72 10.81-15.59q3.53-1.6 10.2-1.82 10.77-0.36 21.59-0.22 8.53 0.11 14.6 6.09zm-53.31 48.18c3.74 6.91 8.84 9.95 16.79 10q11.35 0.08 22.74 0.12c6.12 0.03 11.2-4.85 13.6-10.04q1.28-2.79 1.34-7.41 0.18-13.99 0.29-28 0.05-7.16-5.33-11.89-3.67-3.47-7.06-3.49-13.14-0.06-26.27-0.32c-7.28-0.15-13.17 3.79-16.04 10.39q-1.36 3.15-1.33 8.59 0.09 16.06 1.27 32.05z" fill="#3b02a6"/>
    <g fill="#d036f8">
      <path d="m88.75 28.04c-1.2 2.55-5.74 9.99-9.25 9.75l-3.69-2.18a1 0.99-63.4 0 0-1.43 0.48l-0.29 0.68a1 1 0 0 1-1.18 0.59l-3.17-0.85a1.01 1-82.5 0 1-0.74-0.97v-1.04a1 1 0 0 0-1-1h-3.33a1.01 1 87.4 0 0-1 1.1l0.08 0.79a1.01 1-10.7 0 1-0.73 1.06l-3.2 0.88a1 0.99 68.9 0 1-1.16-0.52l-0.42-0.84a0.99 0.99 0 0 0-1.38-0.42l-2.87 1.61a1 1 0 0 0-0.4 1.33l0.57 1.1a1.01 0.99 55 0 1-0.2 1.18l-2.34 2.26a0.99 0.99 0 0 1-1.19 0.15l-0.92-0.53a1 0.99 29.5 0 0-1.37 0.38l-1.59 2.82a1 1 0 0 0 0.42 1.39l0.84 0.42a1 1 0 0 1 0.52 1.14l-0.81 3.2a1 0.99 7 0 1-0.97 0.75h-1.05a1 1 0 0 0-1 1v3.43a1 1 0 0 0 1.04 1l0.9-0.04a1.01 1.01 0 0 1 1.01 0.74l0.82 3.02a1 0.99-22 0 1-0.49 1.14l-1.62 0.89q-3.9 2.44-5.06 6.72c-0.48 1.77 0.46 3.17-1.78 4.61q-1.18-15.99-1.27-32.05-0.03-5.44 1.33-8.59c2.87-6.6 8.76-10.54 16.04-10.39q13.13 0.26 26.27 0.32 3.39 0.02 7.06 3.49z"/>
      <path d="m73.25 42.04q-0.29 1.35-1.5 2c-11.44-4.8-20.95 5.47-16.71 16.96q-0.92 0.92-2.25 1-5.2-13.7 6.96-20.25 6.39-3.44 13.5 0.29z"/>
    </g>
    <path d="m22.04 80.5h0.17c6.16 8.12 11.29 17.19 17.14 26.4a4.32 4.24 30.6 0 1 0.67 2.52q-0.05 1.11-1.55 0.32-12.86-6.79-25.22-14.45 1.33-1.16 1-3c5.83 3.99 12.36 7.09 18.03 11.11q0.36 0.25 0.73 0.51 5.45 3.81 1.36-1.44-0.29-0.37-0.62-0.71-1.56-1.61-2.07-2.42-5.11-8.14-10.89-15.84l-2.79-2.71q1.06 0.15 1.91 0.74a1.01 1.01 0 0 0 1.29-0.14l0.84-0.89z" fill="#a413b7"/>
    <path d="m27.97 3.26 0.02 1.7a1.06 1.03-68.2 0 0 0.29 0.7l6.85 6.85a1.01 1.01 0 0 1-0.03 1.46q-6.58 5.9-13.28 11.64c-5.64 4.82-9.99-2.38-13.82-7.32q1.98 0.07 3.75-0.83 3.49-1.29 5.46-4.46l10.76-9.74z" fill="#fbd7f7"/>
    <path d="m20.79 83.5q5.78 7.7 10.89 15.84 0.51 0.81 2.07 2.42 0.33 0.34 0.62 0.71 4.09 5.25-1.36 1.44-0.37-0.26-0.73-0.51c-5.67-4.02-12.2-7.12-18.03-11.11l-3.54-2.79q1.24-0.63 2.12-1.76a1 0.99 29.7 0 1 1.17-0.31q1.9 0.79 3.97-0.04a1.05 0.96-81.3 0 0 0.46-0.37l2.36-3.52z" fill="#fd3fe7"/>
    <path d="m22.04 80.5-0.84 0.89a1.01 1.01 0 0 1-1.29 0.14q-0.85-0.59-1.91-0.74c-6.25-2.17-10.6 3-7.29 8.71l3.54 2.79q0.33 1.84-1 3l-2-1.58c-12.21-8.12 3.05-23.95 10.79-13.21z" fill="#6505ae"/>
    <path d="m79.5 37.79-0.95 1.76a1 1 0 0 0 0.17 1.19l2.3 2.3a1.01 1-39.3 0 0 1.27 0.12l0.73-0.5a1.01 1.01 0 0 1 1.43 0.33l1.66 2.86a1.01 1-29 0 1-0.4 1.39l-0.86 0.46a1.01 1 68.8 0 0-0.5 1.15l0.88 3.17a1.01 1 81.9 0 0 0.96 0.73h1.06a1 1 0 0 1 1 1v3.46a1 1 0 0 1-1.15 0.99l-0.74-0.12a1 1 0 0 0-1.11 0.71l-0.91 3.13a1 0.99-66.8 0 0 0.45 1.13l0.97 0.58a1 1 0 0 1 0.35 1.37l-1.72 2.89a1 1 0 0 1-1.45 0.3l-0.61-0.45a1.01 1.01 0 0 0-1.3 0.1l-2.33 2.34a1 1 0 0 0-0.13 1.26l0.46 0.68a1.01 1.01 0 0 1-0.31 1.42l-2.86 1.73a1 0.99-29.5 0 1-1.4-0.38l-0.45-0.83a1 0.99 68.6 0 0-1.13-0.5l-3.13 0.81a1 0.99 83 0 0-0.75 0.97v0.98a1 1 0 0 1-0.99 1l-3.25 0.04a1 1 0 0 1-1.01-1v-1.06a1.01 1-83.5 0 0-0.77-0.98l-3.24-0.75a1.01 1.01 0 0 0-1.11 0.49l-0.48 0.88a1 1 0 0 1-1.39 0.38l-2.85-1.71a1 1 0 0 1-0.34-1.38l0.54-0.9a1 1 0 0 0-0.15-1.22l-2.3-2.31a1 0.99 51.1 0 0-1.24-0.14l-0.78 0.49a1.01 1-30.3 0 1-1.42-0.37l-2.06-3.85 1.62-0.89a1 0.99-22 0 0 0.49-1.14l-0.82-3.02a1.01 1.01 0 0 0-1.01-0.74l-0.9 0.04a1 1 0 0 1-1.04-1v-3.43a1 1 0 0 1 1-1h1.05a1 0.99 7 0 0 0.97-0.75l0.81-3.2a1 1 0 0 0-0.52-1.14l-0.84-0.42a1 1 0 0 1-0.42-1.39l1.59-2.82a1 0.99 29.5 0 1 1.37-0.38l0.92 0.53a0.99 0.99 0 0 0 1.19-0.15l2.34-2.26a1.01 0.99 55 0 0 0.2-1.18l-0.57-1.1a1 1 0 0 1 0.4-1.33l2.87-1.61a0.99 0.99 0 0 1 1.38 0.42l0.42 0.84a1 0.99 68.9 0 0 1.16 0.52l3.2-0.88a1.01 1-10.7 0 0 0.73-1.06l-0.08-0.79a1.01 1 87.4 0 1 1-1.1h3.33a1 1 0 0 1 1 1v1.04a1.01 1-82.5 0 0 0.74 0.97l3.17 0.85a1 1 0 0 0 1.18-0.59l0.29-0.68a1 0.99-63.4 0 1 1.43-0.48l3.69 2.18zm-26.71 24.21c6.99 13.6 26.52 10.23 28.42-5.09q1.14-9.22-7.96-14.87-7.11-3.73-13.5-0.29-12.16 6.55-6.96 20.25z" fill="#32029a"/>
    <g fill="#faf3f7">
      <path d="m7.5 4.21 8.09 0.06a0.65 0.63 76.3 0 1 0.57 0.36c0.76 1.57-4.97 1.3-5.8 1.36a0.97 0.96 20.7 0 0-0.63 0.28q-2.18 2.11-2.19 5.23l-1.94 2.5q-5.75-6.51 1.9-9.79z"/>
      <path d="m18 80.79 2.79 2.71-2.36 3.52a1.05 0.96-81.3 0 1-0.46 0.37q-2.07 0.83-3.97 0.04a1 0.99 29.7 0 0-1.17 0.31q-0.88 1.13-2.12 1.76c-3.31-5.71 1.04-10.88 7.29-8.71z"/>
    </g>
    <g fill="#4c02a4">
      <path d="m27.97 3.26-10.76 9.74-5.46 4.46q-1.77 0.9-3.75 0.83c-10.19-3.32-10.32-17.38 0.81-17.49q13.05-0.14 17.19-0.04 2.26 0.06 1.97 2.5zm-20.47 0.95q-7.65 3.28-1.9 9.79 3.91 1.84 6.52-0.57c2.71-2.51 5.63-4.67 8.13-7.39q0.31-0.11 0.58-0.26 3.02-1.66-0.42-1.88-6.46-0.41-12.91 0.31z"/>
      <rect transform="translate(112.42 17.17) rotate(.8)" x="-7.53" y="-1.59" width="15.06" height="3.18" rx="1.41"/>
    </g>
    <g fill="#663dbf" fillOpacity=".992">
      <path d="m22.61 79.96-0.4 0.54h-0.17c-7.74-10.74-23 5.09-10.79 13.21l-0.5 0.5c-11.56-8 0.06-24.65 11.86-14.25z"/>
      <path d="m107.46 90.5 0.03 0.21a0.27 0.05 1.8 0 0 0.26 0.06l13.81 0.06a1 1 0 0 1 0.98 0.86q0.58 4.15-0.08 8.31l-0.18-7.2a0.99 0.99 0 0 0-1.01-0.97l-13.59 0.2q-0.24 0-0.46-0.1l-1.47-0.72 1.71-0.71z"/>
    </g>
    <path d="m20.25 6.04-8.35 0.56q-1.8 0.11-2.46 2.12-0.39 1.18-1.9 2.78 0.01-3.12 2.19-5.23a0.97 0.96 20.7 0 1 0.63-0.28c0.83-0.06 6.56 0.21 5.8-1.36a0.65 0.63 76.3 0 0-0.57-0.36l-8.09-0.06q6.45-0.72 12.91-0.31 3.44 0.22 0.42 1.88-0.27 0.15-0.58 0.26z" fill="#f194fa"/>
    <path d="m107.5 96.81-0.81-2.09a1 0.99-10.5 0 1 0.94-1.36l11.31 0.09a1 1 0 0 1 0.99 0.95l0.28 5.23a1 1 0 0 1-0.97 1.05q-8.69 0.24-10.56 0.08-3.18-0.27-1.29-3.02a1.01 1-38.7 0 0 0.11-0.93z" fill="#fff"/>
    <path d="m20.25 6.04c-2.5 2.72-5.42 4.88-8.13 7.39q-2.61 2.41-6.52 0.57l1.94-2.5q1.51-1.6 1.9-2.78 0.66-2.01 2.46-2.12l8.35-0.56z" fill="#fc35f8"/>
    <g fill="#8e61d3" fillOpacity=".996">
      <path d="m17.21 13q-1.97 3.17-5.46 4.46l5.46-4.46z"/>
      <path d="m114.5 103.29 0.42 0.71a1.01 1-24.6 0 1-0.55 1.46q-1.79 0.6-1.41-1.96l1.54-0.21z"/>
    </g>
    <path d="m22.61 79.96q15.44 15.44 28.48 32.99 1.11 1.49-0.75 1.59l-8.65 0.46h-9.75q-3.63-0.97-5.63-3.31-7.58-8.92-15.56-17.48l0.5-0.5 2 1.58q12.36 7.66 25.22 14.45 1.5 0.79 1.55-0.32a4.32 4.24 30.6 0 0-0.67-2.52c-5.85-9.21-10.98-18.28-17.14-26.4l0.4-0.54z" fill="#fccefa"/>
    <path d="m105.75 91.21 1.47 0.72q0.22 0.1 0.46 0.1l13.59-0.2a0.99 0.99 0 0 1 1.01 0.97l0.18 7.2q0.14 0.67-0.1 1.42a0.98 0.95 82 0 1-0.38 0.52q-1.57 1.08-1.2 3.05a1.01 1 75.9 0 1-0.68 1.14q-2.12 0.66-1.85-1.8a1 0.98-70.8 0 0-0.45-0.95q-1.48-0.95-3.3-0.09l-1.54 0.21c-6.18-4.05-2.01 4.82-5.87 3.1a0.99 0.99 0 0 1-0.58-0.76l-0.42-2.73q-0.03-0.16-0.1-0.31-1.82-3.52-2.36-7.75-1.06-3.17 2.12-3.84zm1.64 6.53q-1.89 2.75 1.29 3.02 1.87 0.16 10.56-0.08a1 1 0 0 0 0.97-1.05l-0.28-5.23a1 1 0 0 0-0.99-0.95l-11.31-0.09a1 0.99-10.5 0 0-0.94 1.36l0.81 2.09a1.01 1-38.7 0 1-0.11 0.93z" fill="#3709a6"/>
  </svg>
);

export default BiggerOptimization;