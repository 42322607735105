import React from 'react';
import AIPossibilities from '../components/sections/ai-possibilities/ai-possibilities';
import Wave from '../components/transitions/wave/wave';
import FAQ from '../components/sections/faq/faq';
import { Helmet } from 'react-helmet-async';
import HeroSkills from '../components/sections/hero-skills/hero-skills';



function SkillsPage({ texts }) {
  return (
    <>
      <Helmet>
        <title>{texts.titles.skills}</title>
        <meta name="description" content={texts.metaDescriptions.skills}></meta>
      </Helmet>
      <HeroSkills texts={texts.heroSkills} />
      <Wave color="var(--light)" backgroundColor="var(--main-lighter)"/>
      <AIPossibilities texts={texts.AIPossibilities} />
      <Wave color="var(--light-darker)" backgroundColor="var(--light)"/>
      <FAQ texts={texts.faq.skills} />
      <Wave color="var(--main)" backgroundColor="var(--light-darker)"/>
    </>
  );
}



export default SkillsPage;