import React from "react";
export const Instagram = () => (
  <svg version="1.2" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g fill="var(--main)">
      <path d="m31.6 15.82a15.59 15.59 0 0 1-15.59 15.59 15.59 15.59 0 0 1-15.59-15.59 15.59 15.59 0 0 1 15.59-15.59 15.59 15.59 0 0 1 15.59 15.59zm-4.8106-6.1592a4.91 4.91 0 0 0-4.9186-4.9014l-12.14 0.0212a4.91 4.91 0 0 0-4.9014 4.9186l0.0212 12.16a4.91 4.91 0 0 0 4.9186 4.9014l12.14-0.0212a4.91 4.91 0 0 0 4.9014-4.9186z"/>
      <path d="m24.318 22.565a1.87 1.87 0 0 1-1.8733 1.8667l-13.1-0.0228a1.87 1.87 0 0 1-1.8667-1.8733l0.023-13.2a1.87 1.87 0 0 1 1.8733-1.8667l13.1 0.0228a1.87 1.87 0 0 1 1.8667 1.8733zm-1.5337-11.704a1.14 1 60.3 0 0 0.3038-1.4857 1.14 1 60.3 0 0-1.4334-0.4947 1.14 1 60.3 0 0-0.3038 1.4857 1.14 1 60.3 0 0 1.4334 0.4947zm-0.9348 4.9598a5.93 5.93 0 0 0-5.93-5.93 5.93 5.93 0 0 0-5.93 5.93 5.93 5.93 0 0 0 5.93 5.93 5.93 5.93 0 0 0 5.93-5.93z"/>
      <circle cx="15.92" cy="15.8" r="3.63"/>
    </g>
  </svg>
);
export default Instagram;