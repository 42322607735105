import React from "react";
export const OldIntegration = () => (
  <svg className="old-integration" version="1.2" viewBox="0 0 128 92" xmlns="http://www.w3.org/2000/svg">
    <path d="m18 3.29 9.57 0.11a0.35 0.34 14.9 0 1 0.3 0.51q-0.47 0.81-1.62 0.88-4.83-0.83-8.83 1.77l0.58-3.27z" fill="#faaff9"/>
    <path d="m32.52 2.86q2.54 4.01 5.96 7.32a0.49 0.46 46.1 0 1 0.01 0.7l-11.66 10.24q-2.4 2.11-4.58-0.35l-5.5-6.23c4.69-0.32 12.46-8.53 15.77-11.68z" fill="#fbd7f7"/>
    <path d="m26.25 4.79c-1.83 2.22-7.05 8.49-11.23 6.45q1.82-2.28 2.4-4.68 4-2.6 8.83-1.77z" fill="#fd35f8"/>
    <g fill="#4e04a5">
      <path d="m32.52 2.86c-3.31 3.15-11.08 11.36-15.77 11.68-5.45-1.88-9.13-9.11-2.92-12.87 3.15-1.9 13.42-0.91 17.4-0.9 1.43 0 1.9 0.73 1.29 2.09zm-17.5 8.38c4.18 2.04 9.4-4.23 11.23-6.45q1.15-0.07 1.62-0.88a0.35 0.34 14.9 0 0-0.3-0.51l-9.57-0.11q-8.06 1.17-2.98 7.95z"/>
      <rect transform="translate(100.34,13.67) rotate(1.3)" x="-6.04" y="-1.31" width="12.08" height="2.62" rx="1.26"/>
    </g>
    <path d="m79.82 21.94q-7.26 0.95-14.57 0.6l-12.75 0.5c-7.47 0.6-8.51 7.03-8.79 13.46q-1.43 0.74-2.42-0.5c-1.16-12.24 5.91-16.57 16.57-16.53 6.07 0.02 17.15-1.41 21.96 2.47z" fill="#ece2f9"/>
    <path d="m65.25 22.54q-1.78 2.29-0.75 5.17-4.85-0.19-8.96 2.29l-0.52-6.2a0.7 0.49 4 0 0-0.59-0.48l-1.93-0.28 12.75-0.5z" fill="#a153fa"/>
    <path d="m79.82 21.94q4.84 2.98 5.47 9.06c0.09 1.59-0.12 8.28-2.74 8.48a0.9 0.68 68.7 0 1-0.73-0.45q-0.82-1.41-2.53-1.53-0.75-2.05-2.75-2.5-4.21-6.56-12.04-7.29-1.03-2.88 0.75-5.17 7.31 0.35 14.57-0.6z" fill="#cf38f9"/>
    <path d="m52.5 23.04 1.93 0.28a0.7 0.49 4 0 1 0.59 0.48l0.52 6.2q-5.69 3.9-6.83 10.75-1.53 1.26-2.12-0.21a1.26 0.84 81 0 0-0.62-0.67q-0.92-0.3-1.93-0.12l-0.33-3.25c0.28-6.43 1.32-12.86 8.79-13.46z" fill="#d539fd"/>
    <g fill="#4b0278">
      <path d="m85.11 22.09q5.35 7.19 3.72 16.2-0.41 2.28 1.89 2 4.91-0.6 9.78-0.08 5.73 1.55 11.81 0.43 0.3 0.94-0.02 1.46a1.11 0.66 8.9 0 1-0.85 0.34l-10.19 0.1-11.61 0.04a0.51 0.5 90 0 0-0.5 0.51l0.02 3.26a0.5 0.5 0.6 0 0 0.49 0.5l11.83 0.26q5.24 1.31 10.8 0.58c-0.3 2.21-1.27 1.57-2.7 1.67q-3.79 0.26-8.04 0.11l-11.97-0.16a0.57 0.54-86.6 0 0-0.55 0.52q-0.24 2.72-0.48 5.38c-1.83 19.99-21.97 15.33-35.3 15.69q-9.88-1.72-13.58-10.44c-1.33-3.12-0.98-7.16-1.69-10.81a0.61 0.61 84.2 0 0-0.6-0.49l-20.77-0.17a1.35 0.86-12.3 0 1-0.89-0.33c-0.63-0.69-0.04-1.11 0.67-1.14q10.31-0.41 20.61-0.25a0.74 0.68 10.1 0 0 0.69-0.44q0.75-1.95 0.18-3.87a0.67 0.64-8.5 0 0-0.65-0.46q-9.52 0.08-19 0.09-3.56 0-2.09-1.97a1.04 0.97 18.2 0 1 0.82-0.38l20.51 0.31a0.93 0.9 2.3 0 0 0.94-0.84c0.67-9.13-1.08-17.32 9.32-21.64 5.92-2.45 16.72-1.32 23.82-1.33 5.94 0 9.7 1.3 13.58 5.35zm-43.82 13.91 0.25 15.5q-0.37 4.6 1.46 8.79c4.78 9.89 17.66 7.28 29.76 7.2q7.37-0.05 10.84-6.83c2.31-2.64 2.13-15.99 2.11-19.16l-0.42-10.5q-0.63-6.08-5.47-9.06c-4.81-3.88-15.89-2.45-21.96-2.47-10.66-0.04-17.73 4.29-16.57 16.53z"/>
      <path d="m64.5 27.71q7.83 0.73 12.04 7.29c5.73 12.34-2.84 24.63-16.34 22.18q-11.97-3.61-11.49-16.43 1.14-6.85 6.83-10.75 4.11-2.48 8.96-2.29z"/>
    </g>
    <path d="m85.29 31 0.42 10.5-4.92 0.25-1.5-4.25q1.71 0.12 2.53 1.53a0.9 0.68 68.7 0 0 0.73 0.45c2.62-0.2 2.83-6.89 2.74-8.48z" fill="#a042f9"/>
    <path d="m41.29 36q0.99 1.24 2.42 0.5l0.33 3.25q0.04 8.95-0.54 11-0.35 1.25-1.96 0.75l-0.25-15.5z" fill="#c0b9f6"/>
    <g fill="#e3cbf7">
      <path d="m85.11 22.09q11.07 6.17 15.89 17.7l-0.5 0.42q-4.87-0.52-9.78 0.08-2.3 0.28-1.89-2 1.63-9.01-3.72-16.2z"/>
      <path d="m101.25 42.54q-0.79 2.21 0.25 4.25l-0.02 0.32-11.83-0.26a0.5 0.5 0.6 0 1-0.49-0.5l-0.02-3.26a0.51 0.5-90 0 1 0.5-0.51l11.61-0.04z"/>
    </g>
    <path d="m44.04 39.75q1.01-0.18 1.93 0.12a1.26 0.84 81 0 1 0.62 0.67q0.59 1.47 2.12 0.21-0.48 12.82 11.49 16.43-1.48 2.1 1.86 2.93-1.39-0.07-2.6 0.39l-16.46-0.21q-1.83-4.19-1.46-8.79 1.61 0.5 1.96-0.75 0.58-2.05 0.54-11z" fill="#6273f8"/>
    <path d="m85.71 41.5c0.02 3.17 0.2 16.52-2.11 19.16q-9.14-1.43-18.9 0.53-2.56 0.51-5.24-0.69 1.21-0.46 2.6-0.39 3.77-0.58 7.58-1.21c7.85-1.28 11.46-9.86 11.15-17.15l4.92-0.25z" fill="#5f68f6"/>
    <path d="m76.54 35q2 0.45 2.75 2.5l1.5 4.25c0.31 7.29-3.3 15.87-11.15 17.15q-3.81 0.63-7.58 1.21-3.34-0.83-1.86-2.93c13.5 2.45 22.07-9.84 16.34-22.18z" fill="#9babf2"/>
    <g fill="#623aa5" fillOpacity=".996">
      <path d="m101 39.79 11.31 0.85q-6.08 1.12-11.81-0.43l0.5-0.42z"/>
      <path d="m101.5 46.79q5.47-0.42 10.78 0.9-5.56 0.73-10.8-0.58l0.02-0.32z"/>
      <path d="m25 61.21q3.51 1.18 5.25 4.5l-0.29 0.29-1.21-1.04-3.75-3.75z"/>
    </g>
    <path d="m101.54 49.47q1.46 12.18-4.74 22.47-4.27-0.04-3.8 3.77-3.61 5.65-11.26 7.28c-5.75 1.22-13.75 2.08-18.09-2.13q-5.15-5-10.41-9.96c13.33-0.36 33.47 4.3 35.3-15.69q0.24-2.66 0.48-5.38a0.57 0.54-86.6 0 1 0.55-0.52l11.97 0.16z" fill="#e3cdfb"/>
    <path d="m59.46 60.5q2.68 1.2 5.24 0.69 9.76-1.96 18.9-0.53-3.47 6.78-10.84 6.83c-12.1 0.08-24.98 2.69-29.76-7.2l16.46 0.21z" fill="#d130fa"/>
    <path d="m25 61.21 3.75 3.75-1.71 1.29-0.25-0.25c-3.51-5.29-11.37-0.78-7.79 4.79l2.75 2.42q0.14 1.67-1.25 1.83-0.89-1.23-2.25-1c-6.49-6.05-1.2-15.37 6.75-12.83z" fill="#6609af"/>
    <g fill="#fbf3f6">
      <path d="m18 3.29-0.58 3.27q-0.58 2.4-2.4 4.68-5.08-6.78 2.98-7.95z"/>
      <path d="m26.79 66c-1 1.7-2.27 4.75-4.86 3.44a0.96 0.67 36.8 0 0-0.87-0.03l-2.06 1.38c-3.58-5.57 4.28-10.08 7.79-4.79z"/>
    </g>
    <path d="m26.79 66 0.25 0.25 11.27 15.91q1.27 1.8-0.62 0.66l-15.94-9.61-2.75-2.42 2.06-1.38a0.96 0.67 36.8 0 1 0.87 0.03c2.59 1.31 3.86-1.74 4.86-3.44z" fill="#fe40e8"/>
    <path d="m96.25 76.72-0.61-2.02a0.41 0.39 80.8 0 1 0.37-0.53l9.93 0.05a0.56 0.54 90 0 1 0.53 0.55l0.03 4.7a0.52 0.47 0.6 0 1-0.53 0.47l-9.41-0.2a0.51 0.43 4 0 1-0.51-0.49l0.23-1.59q0.08-0.59-0.03-0.94z" fill="#dbd9f9"/>
    <path d="m30.25 65.71q0.78 0.33 1.37 0.92 11.06 11.18 20.3 23.95 0.26 0.36-0.18 0.37-7.75 0.18-15.5 0.37c-3.49 0.09-15-14.22-17.99-17.28q1.36-0.23 2.25 1 9.88 6.4 20.19 12.12c1.47 0.82 2.31-0.38 1.6-1.6q-5.84-9.99-12.33-19.56l0.29-0.29z" fill="#fccefa"/>
    <path d="m96.8 71.94q3.9 0.13 8.7-0.03c4.68-0.15 2.97 4.93 2.98 7.55a1.48 1.35-28.7 0 1-0.31 0.9q-1.06 1.38-1.37 3.11a0.92 0.75 88 0 1-0.52 0.69q-1.58 0.59-1.31-1.27a1.09 1.09-2.4 0 0-1.33-1.22q-2.28 0.54-4.12-0.13a1.31 1.3 11.6 0 0-1.75 1.15q-0.17 3.06-1.96 1.45a1.31 0.98-75.6 0 1-0.35-0.85q-0.05-1.62-1.02-2.83a1.19 1.11 30.8 0 1-0.25-0.86q0.31-2.33-1.19-3.89-0.47-3.81 3.8-3.77zm-0.52 5.72-0.23 1.59a0.51 0.43 4 0 0 0.51 0.49l9.41 0.2a0.52 0.47 0.6 0 0 0.53-0.47l-0.03-4.7a0.56 0.54 90 0 0-0.53-0.55l-9.93-0.05a0.41 0.39 80.8 0 0-0.37 0.53l0.61 2.02q0.11 0.35 0.03 0.94z" fill="#501084"/>
    <path d="m28.75 64.96 1.21 1.04q6.49 9.57 12.33 19.56c0.71 1.22-0.13 2.42-1.6 1.6q-10.31-5.72-20.19-12.12 1.39-0.16 1.25-1.83l15.94 9.61q1.89 1.14 0.62-0.66l-11.27-15.91 1.71-1.29z" fill="#a415b8"/>
  </svg>
);
export default OldIntegration;