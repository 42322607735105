import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHashOrToTopCustom = () => {
  const location = useLocation();

    useLayoutEffect(() => {
        const { hash } = location;

        const removeHashCharacter = (str) => {
            const result = str.slice(1);
            return result;
        };

        if (hash) {
            const args = hash.split("?=");
            const element = document.getElementById(removeHashCharacter(args[0]));

            if (element) {
                element.scrollIntoView({
                    behavior: 'auto',
                    inline: 'nearest',
                });
            }
        }
        else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return null;
};

export default ScrollToHashOrToTopCustom;