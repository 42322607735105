import React from 'react';
import './what.css';
import { useNavigate } from 'react-router-dom';
import { hideNavbar } from '../header/header';
import { addStrongers } from '../../../utils/formatText';



export function What (props) {
  
  const nav = useNavigate();

  return (
    <section className="what">
      <div className="container">
        <div className="row automatize">
          <div className="image-pc">
            <img src="/images/automatize.webp"
                  alt={props.texts.automatize.image.alt} 
                  width="886"
                  height="886"
            />
          </div>
          <div className="text">
            <h1>{props.texts.automatize.title}</h1>

            <p>{addStrongers(props.texts.automatize.zzdescription)}</p>

            <div className="image-phone">
              <img src="/images/automatize.webp" 
                   alt={props.texts.automatize.image.alt} 
                   width="1092"
                   height="1092"
              />
            </div>

            <button onClick={() => {
                nav("/skills");hideNavbar();
              }}
            >
              {props.texts.automatize.button}
            </button>
          </div>
        </div>
        <div className="row analyze">
          <div className="image-pc">
            <img src="/images/analyze.webp"
                  alt={props.texts.analyze.image.alt} 
                  width="886"
                  height="886"
            />
          </div>
          <div className="text">
            <h1>{props.texts.analyze.title}</h1>

            <p>{addStrongers(props.texts.analyze.zzdescription)}</p>

            <div className="image-phone">
              <img src="/images/analyze.webp"
                   alt={props.texts.analyze.image.alt} 
                   width="1092"
                   height="1092"
              />
            </div>

            <button onClick={() => {
                nav("/skills");hideNavbar();
              }}
            >
              {props.texts.analyze.button}
            </button>
          </div>
        </div>
        <div className="row accelerate">
          <div className="image-pc">
            <img src="/images/accelerate.webp"
                  alt={props.texts.accelerate.image.alt} 
                  width="886"
                  height="886"
            />
          </div>
          <div className="text">
            <h1>{props.texts.accelerate.title}</h1>

            <p>{addStrongers(props.texts.accelerate.zzdescription)}</p>

            <div className="image-phone">
              <img src="/images/accelerate.webp"
                   alt={props.texts.accelerate.image.alt} 
                   width="1092"
                   height="1092"
              />
            </div>

            <button onClick={() => {
                nav("/skills");hideNavbar();
              }}
            >
              {props.texts.accelerate.button}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}


export default What;