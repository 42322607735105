import React from 'react';
import './ai-possibilities.css';
import { useNavigate } from 'react-router-dom';
import { hideNavbar } from '../header/header';
import { addStrongers } from '../../../utils/formatText';



export function AIPossibilities (props) {
  
  const nav = useNavigate();

  return (
    <section className="ai-possibilities">
      <div className="container">
        <h1 className="main-title">{props.texts.title}</h1>
        
        <div className="row creativity">
          <div className="image-pc">
            <img src="/images/creativity.webp"
                  alt={props.texts.creativity.image.alt} 
                  width="886"
                  height="886"
            />
          </div>
          <div className="text">
            <h1>{props.texts.creativity.title}</h1>

            <p>{addStrongers(props.texts.creativity.zzdescription)}</p>

            <div className="image-phone">
              <img src="/images/creativity.webp" 
                   alt={props.texts.creativity.image.alt} 
                   width="1092"
                   height="1092"
              />
            </div>

            <button onClick={() => {
                nav("/contact");hideNavbar();
              }}
            >
              {props.texts.creativity.button}
            </button>
          </div>
        </div>



        <div className="row operability inverserow">
          <div className="image-pc">
            <img src="/images/operability.webp"
                  alt={props.texts.operability.image.alt} 
                  width="886"
                  height="886"
            />
          </div>
          <div className="text">
            <h1>{props.texts.operability.title}</h1>

            <p>{addStrongers(props.texts.operability.zzdescription)}</p>

            <div className="image-phone">
              <img src="/images/operability.webp"
                   alt={props.texts.operability.image.alt} 
                   width="1092"
                   height="1092"
              />
            </div>

            <button onClick={() => {
                nav("/contact");hideNavbar();
              }}
            >
              {props.texts.operability.button}
            </button>
          </div>
        </div>


        <div className="row understanding">
          <div className="image-pc">
            <img src="/images/understanding.webp"
                  alt={props.texts.understanding.image.alt} 
                  width="886"
                  height="886"
            />
          </div>
          <div className="text">
            <h1>{props.texts.understanding.title}</h1>

            <p>{addStrongers(props.texts.understanding.zzdescription)}</p>

            <div className="image-phone">
              <img src="/images/understanding.webp"
                   alt={props.texts.understanding.image.alt} 
                   width="1092"
                   height="1092"
              />
            </div>

            <button onClick={() => {
                nav("/contact");hideNavbar();
              }}
            >
              {props.texts.understanding.button}
            </button>
          </div>
        </div>


        <div className="row security inverserow">
          <div className="image-pc">
            <img src="/images/security.webp"
                  alt={props.texts.security.image.alt} 
                  width="886"
                  height="886"
            />
          </div>
          <div className="text">
            <h1>{props.texts.security.title}</h1>

            <p>{addStrongers(props.texts.security.zzdescription)}</p>

            <div className="image-phone">
              <img src="/images/security.webp"
                   alt={props.texts.security.image.alt} 
                   width="1092"
                   height="1092"
              />
            </div>

            <button onClick={() => {
                nav("/contact");hideNavbar();
              }}
            >
              {props.texts.security.button}
            </button>
          </div>
        </div>

        <div className="row locating">
          <div className="image-pc">
            <img src="/images/locating.webp"
                  alt={props.texts.locating.image.alt} 
                  width="886"
                  height="886"
            />
          </div>
          <div className="text">
            <h1>{props.texts.locating.title}</h1>

            <p>{addStrongers(props.texts.locating.zzdescription)}</p>

            <div className="image-phone">
              <img src="/images/locating.webp"
                   alt={props.texts.locating.image.alt} 
                   width="1092"
                   height="1092"
              />
            </div>

            <button onClick={() => {
                nav("/contact");hideNavbar();
              }}
            >
              {props.texts.locating.button}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}


export default AIPossibilities;