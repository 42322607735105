import React from "react";
export const BiggerConsulting = () => (
  <svg className="bigger-consulting" version="1.2" viewBox="0 0 128 108" xmlns="http://www.w3.org/2000/svg">
    <path d="m101.19 9.81q-9-2.83-12.69-2.81c-19.95 0.14-39.92-0.64-59.86 0.22-11.33 0.48-18.79 10.3-20.13 21.02q-0.95 7.52 0.03 27.51c-0.5 6.61-1.41 16.29 2.46 22.96q-1.13 1.65-3.04 1.79-3.19-5.28-3.18-11.5 0.03-18.96-0.17-37.94c-0.17-16.07 11.04-27.29 26.89-27.02q27.63 0.47 55.25 0.49c6.15 0 10.23 1.32 14.44 5.28z" fill="#e7a2fa"/>
    <path d="m101.19 9.81q3.16 1.91 5.1 5.44l-2.38 4.03q-0.39 0.64-0.56 0.79c-2.18 1.75-4.65 3.15-7.46 3.56q-1.31 0.19-2.6 0.62-2.28-3.11-5.79-3.27c-15.59-0.72-33.59 0.47-50.41-0.19-6.5-0.26-14.48-0.45-14.96 7.64q-0.38 6.59-0.28 13.41-8.05 5.72-13.31 13.91-0.98-19.99-0.03-27.51c1.34-10.72 8.8-20.54 20.13-21.02 19.94-0.86 39.91-0.08 59.86-0.22q3.69-0.02 12.69 2.81z" fill="#b45cfd"/>
    <path d="m107.38 10.28c8.73 6.25 17.28 14.42 18.78 25.21 1.52 10.92 0.99 22.78 0.41 32.57-0.41 6.81 0.45 13.54-1.57 19-3.28 8.84-10.3 15.45-19.09 18.2q-4.69 1.48-14.97 1.48-28.06 0.03-56.13-0.01c-6.55-0.01-18.27-10.87-22.5-15.65q7.62 4.27 15.94 4.4 8.98 0.15 59.68-0.03c14.71-0.05 25.82-12.59 25.84-26.95q0.01-20.59-0.06-41.19-0.03-8.73-6.33-17.03z" fill="#e1cafd"/>
    <path d="m93.29 24.25c2.87 4.47 1.46 20.47 1.75 26.5q0.3 15.37-0.52 20.99c-0.88 6.12-8.09 5.78-12.61 5.8q-27.83 0.11-55.62-0.85-4.23-3.43-4.24-8.25-0.01-13.42-0.2-26.6-0.1-6.82 0.28-13.41c0.48-8.09 8.46-7.9 14.96-7.64 16.82 0.66 34.82-0.53 50.41 0.19q3.51 0.16 5.79 3.27zm-1.7549 4.8478a4.79 4.79 0 0 0-4.7816-4.7983l-56.64-0.0989a4.79 4.79 0 0 0-4.7983 4.7816l-0.0703 40.26a4.79 4.79 0 0 0 4.7816 4.7983l56.64 0.0989a4.79 4.79 0 0 0 4.7983-4.7816l0.0703-40.26z" fill="#4400a8"/>
    <path d="m106.29 15.25c5.29 7.26 4.57 19.93 4.09 29.39q-6.8 5.1-15.34 6.11c-0.29-6.03 1.12-22.03-1.75-26.5q1.29-0.43 2.6-0.62c2.81-0.41 5.28-1.81 7.46-3.56q0.17-0.15 0.56-0.79l2.38-4.03z" fill="#9d33fb"/>
    <path d="m65.96 42-6.8 5.5q-2.3 1.87-4.86-0.16l-4.89-3.88q0.76-1.79 0.98-3.61c1.19-9.89 15.36-7.87 15.57 2.15z" fill="#ff81ec"/>
    <path d="m91.465 69.358a4.79 4.79 0 0 1-4.7983 4.7816l-56.64-0.0989a4.79 4.79 0 0 1-4.7816-4.7983l0.0703-40.26a4.79 4.79 0 0 1 4.7983-4.7816l56.64 0.0989a4.79 4.79 0 0 1 4.7816 4.7983l-0.0703 40.26zm-42.055-25.898 4.89 3.88q2.56 2.03 4.86 0.16l6.8-5.5c2.33 4.74-3.01 8-1.43 9.71q1.28 1.37 3.13-1.31c1.7-2.45 4.23-6.14 1.8-9.04a1.82 1.6 24.8 0 1-0.39-0.88c-1.57-12.84-20.28-14.15-22.11 0.26q-0.06 0.45-0.38 0.89l-1.34 1.78a0.99 0.85 55.1 0 0-0.09 0.88c0.98 2.51 3.34 9.48 7.02 6.79a0.42 0.41 48.6 0 0 0.03-0.64q-3.05-2.93-2.79-6.98zm-19.25 9.13q12 8.24 22.33 15.94c5.53 4.12 7.8 2.44 12.6-1.06q9.94-7.23 19.91-14.45c1.03-0.75 2.44-2.29 1.18-3.3a1.96 1.95-43.2 0 0-2.34-0.07c-8.56 6.03-16.68 12.94-25.04 17.52a2.17 2.15 48.4 0 1-2.3-0.15q-11.85-8.55-23.8-16.95c-1.12-0.78-2.99-1.39-3.38 0.41a2.04 2.03-66.8 0 0 0.84 2.11z" fill="#ff34e1"/>
    <path d="m110.38 44.64c0.67 9.97 2.82 30.47-5.38 38.51q-6.95 4.35-13.44 4.52-24.57 0.66-67.35-0.36 0.14-6.41 2.08-10.62 27.79 0.96 55.62 0.85c4.52-0.02 11.73 0.32 12.61-5.8q0.82-5.62 0.52-20.99 8.54-1.01 15.34-6.11z" fill="#8a01ed"/>
    <g fill="#fefeff">
      <path d="m65.96 42c-0.21-10.02-14.38-12.04-15.57-2.15q-0.22 1.82-0.98 3.61-0.26 4.05 2.79 6.98a0.42 0.41 48.6 0 1-0.03 0.64c-3.68 2.69-6.04-4.28-7.02-6.79a0.99 0.85 55.1 0 1 0.09-0.88l1.34-1.78q0.32-0.44 0.38-0.89c1.83-14.41 20.54-13.1 22.11-0.26a1.82 1.6 24.8 0 0 0.39 0.88c2.43 2.9-0.1 6.59-1.8 9.04q-1.85 2.68-3.13 1.31c-1.58-1.71 3.76-4.97 1.43-9.71z"/>
      <path d="m30.16 52.59a2.04 2.03-66.8 0 1-0.84-2.11c0.39-1.8 2.26-1.19 3.38-0.41q11.95 8.4 23.8 16.95a2.17 2.15 48.4 0 0 2.3 0.15c8.36-4.58 16.48-11.49 25.04-17.52a1.96 1.95-43.2 0 1 2.34 0.07c1.26 1.01-0.15 2.55-1.18 3.3q-9.97 7.22-19.91 14.45c-4.8 3.5-7.07 5.18-12.6 1.06q-10.33-7.7-22.33-15.94z"/>
    </g>
    <path d="m21.85 41.84q0.19 13.18 0.2 26.6 0.01 4.82 4.24 8.25-1.94 4.21-2.08 10.62-8.3-1.53-13.21-8.6c-3.87-6.67-2.96-16.35-2.46-22.96q5.26-8.19 13.31-13.91z" fill="#9d2efe"/>
    <path d="m107.38 10.28q6.3 8.3 6.33 17.03 0.07 20.6 0.06 41.19c-0.02 14.36-11.13 26.9-25.84 26.95q-50.7 0.18-59.68 0.03-8.32-0.13-15.94-4.4c-11.93-9.72-11.25-19.28-11.29-35.85q-0.01-8.95 0.09-22.55c0.14-18.46 10.11-31.67 29.89-31.65q28.14 0.03 56.27 0.27c8.27 0.07 14.59 3.14 20.11 8.98zm-99.42 70.22c5.87 10.02 13.28 11.27 25.13 11.26q25.77-0.02 51.54-0.01c9.18 0.01 15.02-1.6 20.37-8.6 8.2-8.04 6.05-28.54 5.38-38.51 0.48-9.46 1.2-22.13-4.09-29.39q-1.94-3.53-5.1-5.44c-4.21-3.96-8.29-5.28-14.44-5.28q-27.62-0.02-55.25-0.49c-15.85-0.27-27.06 10.95-26.89 27.02q0.2 18.98 0.17 37.94-0.01 6.22 3.18 11.5z" fill="#4801ac"/>
    <path d="m11 78.71q4.91 7.07 13.21 8.6 42.78 1.02 67.35 0.36 6.49-0.17 13.44-4.52c-5.35 7-11.19 8.61-20.37 8.6q-25.77-0.01-51.54 0.01c-11.85 0.01-19.26-1.24-25.13-11.26q1.91-0.14 3.04-1.79z" fill="#fd24f8"/>
  </svg>
);

export default BiggerConsulting;