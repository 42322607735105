import React from 'react';
import './mia-ad.css';
import { Link } from 'react-router-dom';
import { addStrongers } from '../../../utils/formatText';



export function MiaAd (props) {

  return (
    <section className="mia-ad" id="mia">
      <div className="container">
        <div className="row">
          <div className="text">
            <h1>{props.texts.title}</h1>

            <h2 className="company">{props.texts.company}</h2>

            <h2 className="quote">{props.texts.quote}</h2>

            <div className="image-phone">
              <Link to={props.texts.url} target="_blank">
                <img src="/images/mia-ad.webp" 
                    alt={props.texts.image.alt} 
                    width="1092"
                    height="1092"
                />
              </Link>
            </div>

            <p>{addStrongers(props.texts.zzdescription)}</p>

            <button onClick={() => {
                window.open("http://mia.univ-larochelle.fr", '_blank')
              }}
            >
              {props.texts.button}
            </button>
          </div>


          <div className="image-pc">
            <Link to={props.texts.url} target="_blank">
              <img src="/images/mia-ad.webp"
                    alt={props.texts.image.alt} 
                    width="886"
                    height="886"
              />
            </Link>
          </div>
          
        </div>
      </div>
    </section>
  );
}

export default MiaAd;