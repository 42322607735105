import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';


export function Footer (props) {
  
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="container">
        <div className="copyright">
            &copy; {props.texts.company} {currentYear}
        </div>

        <hr />
        
        <nav>
          <ul className="wrapper">
            <li>
              <Link to={"/about"}>{props.texts.about}</Link>
            </li>
            <li>
              <Link to={"/skills"}>{props.texts.skills}</Link>
            </li>
            <li className="li-mid">
              <Link to={"/"}>{props.texts.home}</Link>
            </li>
            <li>
              <Link to={"/contact"}>{props.texts.contact}</Link>
            </li>
            <li>
              <Link to={"/notice"}>{props.texts.notice}</Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;