import React from 'react';
import './services.css';
import { BiggerConsulting } from '../../icons/bigger-consulting';
import { BiggerDevelopment } from '../../icons/bigger-development';
import { BiggerOptimization } from '../../icons/bigger-optimization';
import { BiggerIntegration } from '../../icons/bigger-integration';
import { addStrongers } from '../../../utils/formatText';


export function Services (props) {
  
  

  return (
    <section className="services">
      <div className="container">
        <h1>{props.texts.title}</h1>
        
        <div className="rows">
          <div className="row">
            <BiggerConsulting />

            <h2>{props.texts.consulting.title}</h2>
            <p>
              {addStrongers(props.texts.consulting.zzdescription)}
            </p>
          </div>

          <div className="row">
            <BiggerDevelopment />

            <h2>{props.texts.development.title}</h2>

            <p>
              {addStrongers(props.texts.development.zzdescription)}
            </p>
          </div>
          
          <div className="row">
            <BiggerOptimization />

            <h2>{props.texts.optimization.title}</h2>

            <p>
              {addStrongers(props.texts.optimization.zzdescription)}
            </p>
          </div>

          <div className="row">
            <BiggerIntegration />

            <h2>{props.texts.integration.title}</h2>

            <p>
              {addStrongers(props.texts.integration.zzdescription)}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;