import React from 'react';
import './about.css';
import { useNavigate } from 'react-router-dom';
import { hideNavbar } from '../header/header';
import { addStrongers } from '../../../utils/formatText';

export function About (props) {
  
  const nav = useNavigate();

  return (
    <section className="about">
      <div className="container">
        <h1>{props.texts.title}</h1>
        <p>
          {addStrongers(props.texts.description.zzpart1)}
        </p>

        <p>
          {addStrongers(props.texts.description.zzpart2)}
        </p>
        
        <button onClick={() => {
              nav("/contact");hideNavbar();
            }}
        >
          {props.texts.button}
        </button>
      </div>
    </section>
  );
}

export default About;