import React from 'react';
import './hero-about.css';
import { useNavigate } from 'react-router-dom';
import { hideNavbar } from '../header/header';
import { addStrongers } from '../../../utils/formatText';


export function HeroAbout (props) {
  
  
  const nav = useNavigate();

  return (
    <section className="hero-about">
      <div className="container">
        <div className="content">
          <div className="left">
            <div className="text">
              <p>{props.texts.title}</p>

              <h1>
                {addStrongers(props.texts.description.zzpart1)}

                <br/>

                {addStrongers(props.texts.description.zzpart2)}

              </h1>
            </div>

            <img className="phone-img"
              src="/images/robot.webp"
              alt={props.texts.image.alt}
              width="400"
              height="450"
            />

            <button onClick={() => {
                nav("/contact");hideNavbar();
              }}
            >
              {props.texts.button}
            </button>
          </div>
          
          <div className="right">
              <img className="pc-img" 
                src="/images/robot-thinking-reversed-smile.webp"
                alt={props.texts.image.alt}
                width="420"
                height="499"
              />
          </div>
        </div>
      </div>
    </section>
  );
}


export default HeroAbout;