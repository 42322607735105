import React from 'react';
import Wave from '../components/transitions/wave/wave';
import LegalNotice from '../components/sections/legal-notice/legal-notice';
import { Helmet } from 'react-helmet-async';



function LegalNoticePage({ texts }) {
  return (
    <>
      <Helmet>
        <title>{texts.titles.notice}</title>
        <meta name="description" content={texts.metaDescriptions.notice}></meta>
      </Helmet>
      <LegalNotice texts={texts.legalNotice} />
      <Wave color="var(--main)" backgroundColor="var(--light)"/>
    </>
  );
}



export default LegalNoticePage;