import React from 'react';
import './bar-sm.css';
import {Linkedin} from '../../icons/linkedin';
import {Github} from '../../icons/github';
import {Instagram} from '../../icons/instagram';
import { Link } from 'react-router-dom';

export function BarSM (props) {
  
  

  return (
    <section className="bar-sm">
      <div className="container">
        <hr />
        <div className="sm-container">
          <Link to={props.texts.linkedin}
                target="_blank"
          >
            <Linkedin />
          </Link>
          <Link to={props.texts.github}
                target="_blank"
          >
            <Github />
          </Link>
          <Link to={props.texts.instagram}
                target="_blank"
          >
            <Instagram />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default BarSM;