import React from 'react';
import './legal-notice.css';


export function LegalNotice (props) {
  
  

  return (
    <section className="legal-notice">
      <div className="container">
        <h1>{props.texts.gcs}</h1>
        <p>{props.texts.gcstxt}</p>

        <h2>{props.texts.property}</h2>
        <p>{props.texts.propertytxt}</p>

        <h2>{props.texts.scope}</h2>
        <p>{props.texts.scopetxt}</p>

        <h2>{props.texts.prices}</h2>
        <p>{props.texts.pricestxt}</p>

        <h2>{props.texts.payment}</h2>
        <p>{props.texts.paymenttxt}</p>

        <h2>{props.texts.refunds}</h2>
        <p>{props.texts.refundstxt}</p>

        <h2>{props.texts.warranty}</h2>
        <p>{props.texts.warrantytxt}</p>

        <h2>{props.texts.liability}</h2>
        <p>{props.texts.liabilitytxt}</p>

        <h2>{props.texts.governing}</h2>
        <p>{props.texts.governingtxt}</p>

        <h2>{props.texts.contact}</h2>
        <p>{props.texts.contacttxt}</p>

        <h2>{props.texts.company}</h2>
        <p>{props.texts.companytxt}</p>

        <h2>{props.texts.host}</h2>
        <p>{props.texts.hosttxt}</p>

        <h2>{props.texts.data}</h2>
        <p>{props.texts.datatxt}</p>
      </div>
    </section>
  );
}

export default LegalNotice;