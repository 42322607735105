import React from 'react';
import Wave from '../components/transitions/wave/wave';
import ErrorSection from '../components/sections/error-section/error-section';
import { Helmet } from 'react-helmet-async';



function NotFoundPage({ texts }) {
  return (
    <>
      <Helmet>
        <title>{texts.titles.notFound}</title>
        <meta name="description" content={texts.metaDescriptions.notFound}></meta>
      </Helmet>
      <ErrorSection
        errorCode="404"
        errorMessage={ texts.notFound.oops }
        redirectTo="/"
        redirectionMessage={ texts.notFound.goHome }
      />
      <Wave color="var(--main)" backgroundColor="var(--light)"/>
    </>
  );
}



export default NotFoundPage;