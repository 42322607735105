import React from 'react';
import './cta-rich.css';
import { useNavigate } from 'react-router-dom';
import { hideNavbar } from '../header/header';
import { addStrongers } from '../../../utils/formatText';
import { ThreeDPuzzlePeaceTwo } from '../../icons/3d-puzzle-peace2';



export function CTARich (props) {
  
  const nav = useNavigate();

  return (
    <section className="cta-rich">
      <div className="container">
        <ThreeDPuzzlePeaceTwo />

        <h1>{props.texts.title}</h1>
        
        <p>
          {addStrongers(props.texts.description.zzpart1)}
        </p>
       
        <p>
          {addStrongers(props.texts.description.zzpart2)}
        </p>

        <button onClick={() => {
              nav("/contact");hideNavbar();
            }}
        >
          {props.texts.button}
        </button>
      </div>
    </section>
  );
}


export default CTARich;