import React, { useState } from 'react';
import './contactForm.css';
import { Mail } from '../../icons/mail';



export function ContactForm (props) {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  var emailjs = require("@emailjs/browser");

  emailjs.init(process.env.REACT_APP_EMAILJS_PUBKEY);
 
  const handleSubmit = (event) => {
    event.preventDefault();
    
    try {
      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        message: message 
      }).then(() => {
      props.sendNotif(true);
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
      });
    }
    catch (error) {
      props.sendNotif(false)
    }
  }
  

  
  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <div className="heading">
        <div className="heading-text">
          <h1 className="heading-title">{props.texts.contact}</h1>


          <div className="email-container">
            <Mail />
            <p className="our-email">{props.texts.ourEmail}</p>
          </div>

          <p className="heading-desc">{props.texts.description}</p>
        </div>

        <img
          src="/images/robot.webp"
          alt={props.texts.robotalt}
          width="302"
          height="340"
        />
      </div>

      <hr/>

      <div className="identity-block">
        <label>
          <p>{props.texts.firstname.label}</p>
          <input required type="text" value={firstName}
                onChange={ (event) => setFirstName(event.target.value) } 
                placeholder={ props.texts.firstname.placeholder }
          />
        </label>

        <label>
          <p>{ props.texts.lastname.label }</p>
          <input required type="text" value={lastName}
                onChange={ (event) => setLastName(event.target.value)}
                placeholder={ props.texts.lastname.placeholder }
      />
        </label>
      </div>

      <label>
        <p>{ props.texts.email.label }</p>
        <input required type="email" value={email}
               onChange={ (event) => setEmail(event.target.value) } 
               placeholder={ props.texts.email.placeholder }
     />
      </label>

      <label>
        <p>{ props.texts.message.label }</p>
        <textarea required type="text"
                  value={message}
                  onChange={ (event) => setMessage(event.target.value) } 
                  placeholder={ props.texts.message.placeholder }
        />
      </label>

      <button type="submit">{ props.texts.send }</button>

    </form>
  );
}

export default ContactForm;