import React from 'react';
import './cta.css';
import { useNavigate } from 'react-router-dom';
import { hideNavbar } from '../header/header';
import { PurpleThing4 } from '../../icons/purple-thing4';
import { PurpleThing7 } from '../../icons/purple-thing7';
import { PurpleThing8 } from '../../icons/purple-thing8';
import { addStrongers } from '../../../utils/formatText';



export function CTA (props) {
  const nav = useNavigate();

  return (
    <section className="cta">
      <div className="container">
        <PurpleThing4 />
        <div className="content">
          <h1>{props.texts.heading}</h1>

          <hr />

          <p>
            {addStrongers(props.texts.zzdescription)}
          </p>

          <button onClick={() => {
              nav("/contact");hideNavbar();
            }}
          >
            {props.texts.button}
          </button>
        </div>

        <PurpleThing7 />
        <PurpleThing8 />
      </div>
    </section>
  );
}

export default CTA;